// import AudioPlayer from '../Audio'
import Button from '../Button'
import Drawer from '../Drawer'
import Typography from '../Typography'
import { Row } from './styles'
import { TrimDrawerProps } from './types'
import axios from 'axios'

import Range from '../Range'
import WaveformGenerator from '../../utils/libs/generateWaveform'
import { useTheme } from 'styled-components'
import { useEffect, useState } from 'react'
import { getMusicInfo, waveformSettings } from './constants'
import { files } from '../../utils/api'
import Audio from '../Audio'

const TrimDrawer = (props: TrimDrawerProps) => {
  const {
    visible,
    onClose,
    selectedMusic,
    setStartTime,
    duration,
    startTime,
    handlePlay,
    handlePause,
    hanleChangeMusic
  } = props

  const theme = useTheme()
  const [selectedMusicWaveform, setSelectedMusicWaveform] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const handleComplete = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    if (selectedMusic && duration) {
      const result = await files().music.upload({
        musicUrl: selectedMusic?.musicUrl,
        trimAudioDuration: duration,
        trimAudioStart: startTime,
        fadeEffect: true
      })
      if (result?.musicUrl) {
        hanleChangeMusic(result?.musicUrl)
      }
    }
    setIsLoading(false)
  }

  const handleRangeChange = (value: number) => {
    setStartTime(Math.round(value * 10) / 10)
  }

  useEffect(() => {
    const fetchWaveform = async () => {
      setSelectedMusicWaveform('')
      if (selectedMusic?.musicUrl) {
        const res = await axios.get<ArrayBuffer>(selectedMusic?.musicUrl, {
          responseType: 'arraybuffer',
          headers: { 'Access-Control-Allow-Origin': '*' }
        })
        if (res?.data) {
          const audioBuffer = res.data
          const waveformGenerator = new WaveformGenerator(audioBuffer)
          const waveformResult = await waveformGenerator.getWaveform({
            ...waveformSettings
          })
          if (waveformResult) {
            setSelectedMusicWaveform(waveformResult)
          }
        }
      }
    }
    fetchWaveform()
  }, [selectedMusic, theme])

  return (
    <Drawer
      style={{ height: 'unset' }}
      position='bottom'
      visible={visible}
      title='구간 설정'
      closable
      onClose={onClose}
    >
      <Row>{getMusicInfo(selectedMusic)}</Row>
      <Row>
        {selectedMusic && (
          <Range
            background={selectedMusicWaveform}
            originalDuration={selectedMusic.musicLength}
            duration={duration}
            value={startTime}
            onChange={(value) => handleRangeChange(value)}
          />
        )}
      </Row>
      <Row>
        <Typography block type='body2' bold>
          설정한 구간 듣기
        </Typography>
      </Row>
      <Row>
        {visible && (
          <div style={{ width: '100%' }}>
            <Audio
              src={selectedMusic?.musicUrl}
              duration={duration}
              startTime={startTime}
              handlePlay={handlePlay}
              handlePause={handlePause}
            />
          </div>
        )}
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button size='small' secondary onClick={onClose}>
          닫기
        </Button>
        <Button
          size='small'
          primary
          onClick={handleComplete}
          disabled={isLoading}
        >
          완료
        </Button>
      </Row>
    </Drawer>
  )
}

export default TrimDrawer
