import styled, { css } from 'styled-components'
import Container from '../../components/Container'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundEditor};
`

export const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

export const Layout = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  align-items: top;
  justify-content: center;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      @supports (-webkit-touch-callout: none) {
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
      }
    `)};
`

export const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ScreenWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 305px);
`
