import { useEffect, useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import styled from 'styled-components'

import { iconPause, iconPlay } from '../../assets/icons'

interface AudioProps {
  src?: string
  duration?: number
  startTime?: number
  handlePlay: (audio: HTMLAudioElement) => void
  handlePause: (audio: HTMLAudioElement) => void
}

const Audio = ({
  src,
  handlePlay,
  handlePause,
  duration,
  startTime = 0
}: AudioProps) => {
  // NOTE: using any due to library type issue
  const ref = useRef<any>()
  const audio = ref.current && ref.current.audio.current

  useEffect(() => {
    if (audio && startTime! > 0) {
      if (audio.paused) {
        handlePlay(audio)
      }
      audio.currentTime = startTime!
    }
  }, [audio, startTime])

  useEffect(() => {
    return () => {
      handlePause(audio)
    }
  }, [])

  return (
    <Wrapper>
      <AudioPlayer
        defaultDuration={duration || ''}
        preload='metadata'
        ref={ref}
        src={src}
        autoPlay={false}
        layout={'horizontal-reverse'}
        customVolumeControls={[]}
        showJumpControls={false}
        customAdditionalControls={[]}
        customIcons={{
          play: <img width={18} height={18} src={iconPlay} alt={'play'} />,
          pause: <img width={18} height={18} src={iconPause} alt={'pause'} />
        }}
        onPlay={(e) => handlePlay(e.currentTarget as HTMLAudioElement)}
        onPause={(e) => handlePause(e.currentTarget as HTMLAudioElement)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding-right: 16px;
  /* // NOTE: library custom styling */
  font-feature-settings: 'tnum', 'tnum';

  .rhap_container {
    padding: 0;
    box-shadow: none;
  }

  .rhap_main-controls-button {
    margin: 0;
  }

  .rhap_main {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .rhap_time {
    font-size: 0.75em;
  }

  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 10px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
  }

  .rhap_progress-bar {
    height: 4px;
    border-radius: 0;
  }
  .rhap_progress-filled {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 0;
  }

  .rhap_progress-indicator {
    display: none;
  }

  .rhap_play-pause-button {
    width: auto;
    height: auto;
    font-size: inherit;
  }

  .rhap_controls-section {
    margin-right: 0;
    flex: none;
  }
`

export default Audio
