import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import Modal, { ModalProps } from '../../components/Modal'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import { iconContentPhoto } from '../../assets/icons'
import resizeImage from '../../utils/libs/resizeImage'
import CropImage from '../CropFile/CropImage'
import useLoading from '../../hooks/useLoading'
import FileLoading from '../CropFile/FileLoading'
import useFetchPexels, { MEDIA_TYPE } from '../../hooks/useFetchPexels'
import {
  FileInput,
  Footer,
  PreviewPanel,
  PreviewTitle,
  Wrapper
} from './_stylesModal'
import PhotoModalTabs from './PhotoModalTabs'

//TYPE //////////////////////////////////////////////

type SourceSizeType = {
  sourceWidth: number
  sourceHeight: number
}

type PhotoModalProps = ModalProps & {
  value?: string
  onComplete?(dataUrl: string): void
  aspect?: number
  isError?: boolean
  sourceSize: SourceSizeType
  innerHeight?: number
}

//TYPE FIN //////////////////////////////////////////////

const PhotoModal = (props: PhotoModalProps) => {
  const {
    onClose,
    visible,
    onComplete,
    aspect = 1,
    isError = false,
    sourceSize
  } = props

  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentStep, setCurrentStep] = useState(0)

  const [selectedImageUrl, setSelectedImageUrl] = useState('')
  const [query, setQuery] = useState('')
  const [showDropbox, setShowDropBox] = useState(false)
  const [orientation, setOrientation] = useState<string>('')

  // 크롭 로딩 중일 땐 모달 닫기 불가
  const closable = !(currentStep === 2 && !isError)

  const {
    isLoading: previewLoading,
    renderLoading: previewLoad,
    startLoading: previewLoadStart,
    endLoading: previewLoadEnd
  } = useLoading()

  const {
    renderLoading: listLoad,
    startLoading: listLoadStart,
    endLoading: listLoadEnd
  } = useLoading()

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0)
        setSelectedImageUrl('')
      }, 500)
    }
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [visible])

  const handleInputFileChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((event) => {
    const file = event?.target?.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        if (reader?.result) {
          const res = await resizeImage(reader.result as string, 1920)
          if (res) {
            setSelectedImageUrl(res)
          }
        }
      }
    }
    event.target.value = ''
  }, [])

  //NOTE: PEXEL

  const {
    media: imageList,
    fetchNextPage,
    refetchMedia: refetchImage,
    isFetching
  } = useFetchPexels({
    type: MEDIA_TYPE.PHOTO,
    words: query,
    orientation: orientation,
    preventFetch: !visible
  })

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    setQuery(formData.get('query') as string)
  }

  useEffect(() => {
    isFetching ? listLoadStart() : listLoadEnd()
  }, [isFetching, listLoadEnd, listLoadStart])

  // NOTE: Refetches if orientation changes

  useEffect(() => {
    setShowDropBox(query !== '')
  }, [query])

  useEffect(() => {
    visible && refetchImage()
  }, [orientation, refetchImage])

  //NOTE: PEXEL FIN

  return (
    <Modal
      title='사진 불러오기'
      visible={visible}
      onClose={onClose}
      flowHidden
      closable={closable}
    >
      <div
        style={{
          width: '100%',
          height: 'auto'
        }}
      >
        <FileInput
          ref={inputRef}
          type='file'
          accept='image/*'
          data-testid='input-image'
          onChange={handleInputFileChange}
        />
        {currentStep === 0 && (
          <Wrapper>
            <PreviewPanel>
              <PreviewTitle>
                <Typography bold block center type='body2'>
                  미리보기
                </Typography>
              </PreviewTitle>
              <ImageWrapper innerHeight={document.documentElement.clientHeight}>
                {!selectedImageUrl && (
                  <>
                    <img
                      src={iconContentPhoto}
                      alt='preview'
                      style={{ width: 56, marginBottom: 16 }}
                    />
                    <Typography
                      block
                      center
                      type='body2'
                      style={{ color: theme.colors.text.secondary }}
                    >
                      가지고 계신 사진 또는 무료 사진 중 <br />
                      마음에 드는 것을 선택해주세요.
                    </Typography>
                  </>
                )}
                {selectedImageUrl && (
                  <PhotoLottieWrapper>{previewLoad()}</PhotoLottieWrapper>
                )}
                {selectedImageUrl && (
                  <img
                    onLoad={() => previewLoadEnd()}
                    className='selected-image'
                    src={selectedImageUrl}
                    alt='preview'
                  />
                )}
              </ImageWrapper>
            </PreviewPanel>
            <PhotoModalTabs
              inputRef={inputRef}
              handleSearch={handleSearch}
              showDropbox={showDropbox}
              setOrientation={setOrientation}
              imageList={imageList}
              isFetching={isFetching}
              listLoad={listLoad}
              fetchNextPage={fetchNextPage}
              selectedImageUrl={selectedImageUrl}
              setSelectedImageUrl={setSelectedImageUrl}
              previewLoadStart={previewLoadStart}
              previewLoading={previewLoading}
            />
            <Footer>
              <Button
                primary
                size='small'
                disabled={!selectedImageUrl || previewLoading}
                onClick={() => setCurrentStep(1)}
                loading={previewLoading}
              >
                불러오기
              </Button>
            </Footer>
          </Wrapper>
        )}
        {currentStep === 1 && (
          <CropImage
            image={selectedImageUrl}
            aspect={aspect}
            restrictPosition
            onCrop={async (dataUrl) => {
              setCurrentStep(2)
              onComplete && onComplete(dataUrl)
            }}
            sourceSize={sourceSize}
          />
        )}
        {currentStep === 2 && (
          <FileLoading
            isError={isError}
            onClose={onClose ? onClose : () => {}}
          />
        )}
      </div>
    </Modal>
  )
}

export default PhotoModal

// STYLE ////////////////////////////////////////

const ImageWrapper = styled.div<{ innerHeight?: number }>`
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%; // NOTE: mobile issue
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .selected-image {
    width: 100%;
    height: 30vh;
    object-fit: contain;
    object-position: center center;

    ${({ theme, innerHeight }) =>
      theme.breakpoints.small(css`
        max-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}
  }

  ${({ theme }) =>
    theme.breakpoints.small(css`
      padding-top: 0px;
      padding-bottom: 0px;
    `)}
`

const PhotoLottieWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`
