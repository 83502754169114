import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { IconBlank, IconSearch } from '../../assets/icons'
import AspectRatioSizer from '../../components/AspectRatioSizer'
import InputElement from '../../components/Input'
import useFetchPexels, { MEDIA_TYPE } from '../../hooks/useFetchPexels'
import useLoading from '../../hooks/useLoading'
import { VideoData } from '../../utils/api/images'
import {
  BlankWrapper,
  Content,
  ImageList,
  ImageListItem,
  InputWrapper,
  LottieWrapper,
  ObserveElement,
  SearchForm
} from './_stylesModal'

interface VideoListProps {
  minDuration?: number
  visible?: boolean
  fileLoading: boolean
  onClickItem: (video: VideoData) => void
  selectedImageUrl?: string
}

const VideoList = ({
  minDuration,
  visible,
  fileLoading,
  onClickItem,
  selectedImageUrl
}: VideoListProps) => {
  const [query, setQuery] = useState('')
  const {
    renderLoading: listLoad,
    startLoading: listLoadStart,
    endLoading: listLoadEnd
  } = useLoading()
  const {
    media: videoList,
    fetchNextPage,
    isFetching
  } = useFetchPexels({
    type: MEDIA_TYPE.VIDEO,
    words: query,
    minDuration: minDuration,
    preventFetch: !visible
  })
  const hasResult = videoList?.pages[0] && videoList.pages[0]?.total > 0

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    setQuery(formData.get('query') as string)
  }

  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    isFetching ? listLoadStart() : listLoadEnd()
  }, [isFetching, listLoadEnd, listLoadStart])

  useEffect(() => {
    inView && fetchNextPage()
  }, [fetchNextPage, inView])

  return (
    <>
      <SearchForm onSubmit={handleSearch}>
        <InputWrapper>
          <InputElement
            suffixElement={<IconSearch />}
            name='query'
            placeholder='검색하기 (영어로 입력해주세요.)'
            block
            type='search'
            inputMode='search'
            autoComplete='off'
          />
        </InputWrapper>
      </SearchForm>
      <Content>
        <div style={{ width: '100%' }}>
          {hasResult ? (
            <ImageListWrapper>
              <ImageList
                style={{
                  flex: 1,
                  width: '100%',
                  overflowY: 'auto'
                }}
              >
                {videoList &&
                  videoList.pages.map((page) => {
                    return (
                      page &&
                      page.data.map((d) => {
                        const videoData = d as VideoData
                        const video = videoData.video
                        return (
                          <React.Fragment key={videoData.thumbnail || ''}>
                            {video && (
                              <ImageListItem
                                isPreviewLoading={fileLoading}
                                src={videoData.thumbnail}
                                selected={videoData.video === selectedImageUrl}
                                onClick={() => onClickItem(videoData)}
                              >
                                <AspectRatioSizer aspect={16 / 9} />
                              </ImageListItem>
                            )}
                          </React.Fragment>
                        )
                      })
                    )
                  })}
              </ImageList>
            </ImageListWrapper>
          ) : (
            <>
              {!isFetching && (
                <BlankWrapper>
                  <IconBlank />
                  <span>검색 결과가 없습니다.</span>
                </BlankWrapper>
              )}
            </>
          )}
          {isFetching ? (
            <LottieWrapper>{listLoad()}</LottieWrapper>
          ) : (
            <>{hasResult && <ObserveElement ref={observeRef} />}</>
          )}
        </div>
      </Content>
    </>
  )
}

export default VideoList

const ImageListWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
