import React from 'react'
import styled from 'styled-components'

interface VideoProps {
  style?: React.CSSProperties
  className?: string
  videoClassName?: string
  src?: string
  handleLoadedData: () => void
  handlePreviewError: () => void
  muted?: boolean
  autoPlay?: boolean
  playsInline?: boolean
  onError?: () => void
  onPlay?: () => void
  isPreviewError: boolean
}

const Video = ({
  style,
  className,
  videoClassName,
  src,
  isPreviewError,
  onPlay,
  handleLoadedData,
  handlePreviewError,
  ...props
}: VideoProps) => {
  return (
    <div style={style} className={className}>
      {!isPreviewError && src && (
        <video
          className={videoClassName}
          src={src}
          onLoadedMetadata={handleLoadedData}
          onError={handlePreviewError}
          onPlay={onPlay}
          {...props}
        />
      )}
      {isPreviewError && (
        <ErrorWrapper>
          <span>오류가 발생했습니다.</span>
          <span>다시 시도해주세요.</span>
          <br />
          <span>오류가 반복될 경우</span>
          <span>mp4 파일로 시도하시거나</span>
          <span>비정상적인 파일인지</span>
          <span>확인 바랍니다.</span>
        </ErrorWrapper>
      )}
    </div>
  )
}

export default Video

const ErrorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  span {
    line-height: 1.5;
  }
`
