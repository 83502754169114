import { message } from 'antd'
import styled, { css } from 'styled-components'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Typography from '../../components/Typography'
import useBreakpoint from '../../hooks/useBreakpoint'
import { useProjectContext } from '../../hooks/useProject'
import { Project } from '../../types/project'
import { UseModalProps } from '../../types/useModal'
import { service } from '../../utils/api'

interface RenderModalProps {
  projectId: string
  renderModal: UseModalProps
  sendMessageToParent: (obj: { code: string; data?: string | Project }) => void
  maxCount: number
}

const RenderModal = ({
  projectId,
  renderModal,
  sendMessageToParent,
  maxCount
}: RenderModalProps) => {
  const { needCheck, renderCnt } = useProjectContext()
  const isLimit = needCheck
    ? renderCnt !== undefined && renderCnt >= maxCount
    : false
  const { isBreakpoint } = useBreakpoint()

  const modalWrapperStyle = css`
    max-width: ${!isBreakpoint('large') ? 542 : '100%'};
  `
  const handleRenderClick = async () => {
    if (isLimit) {
      message.error('무료 렌더링 횟수를 초과하였습니다.')
      renderModal.close()
      return
    }
    const res = await service().projects.changeStatus(projectId, 1)
    if (res?._id) {
      renderModal.close()
      sendMessageToParent({ code: 'ROUTE', data: '/projects' })
    }
  }

  return (
    <Modal
      onClose={() => renderModal.close()}
      visible={renderModal.visible}
      style={modalStyle}
      fitContent
      title='안내'
      noFullHeight
      wrapperStyle={modalWrapperStyle}
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: 8 }}>
          <Typography type='body1' bold>
            작업한 프로젝트를
            <br />
            이대로 완료하시겠습니까?
          </Typography>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Typography type='body2'>
            렌더링을 시작하는 동안 프로젝트를 수정할 수 없으며,
            <br />
            렌더링 중에는 편집 데이터가 원활히 저장되지 않을 수 있습니다.
          </Typography>
        </div>
        <ButtonWrapper>
          <Button secondary block onClick={() => renderModal.close()}>
            취소
          </Button>
          <Button
            primary
            block
            onClick={() => {
              // 2022.08.12 임시로 해상도 선택 과정 패스
              // plugins?.includes('fhd')
              //   ? resolutionModal.open()
              //   : handleRenderClick()
              handleRenderClick()
            }}
          >
            완료
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

export default RenderModal

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const modalStyle = css`
  min-width: 40%;
  max-width: 90%;
  margin: 0 auto;
`
