import {
  IconContentDuration,
  IconContentPhoto,
  IconContentVideo,
  IconContentText
} from '../../assets/icons'
import { CountProps } from './Count'

export const getCountItems = ({ duration, photo, video, text }: CountProps) => [
  {
    icon: <IconContentDuration />,
    title: '재생시간',
    content: `${duration}초`
  },
  {
    icon: <IconContentPhoto />,
    title: '사진',
    content: `${photo?.current || 0} / ${photo?.required || 0}`
  },
  {
    icon: <IconContentVideo />,
    title: '영상',
    content: `${video?.current || 0} / ${video?.required || 0}`
  },
  {
    icon: <IconContentText />,
    title: '텍스트',
    content: `${text?.current || 0} / ${text?.required || 0}`
  }
]
