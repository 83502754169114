import {
  IconContentPhoto,
  IconContentVideo,
  IconContentText
} from '../../assets/icons'
import { Source } from '../../types/template'

export const screenSourceCounts = [
  {
    icon: <IconContentPhoto />,
    type: 'I'
  },
  {
    icon: <IconContentVideo />,
    type: 'V'
  },
  {
    icon: <IconContentText />,
    type: 'T'
  }
]

export const getCount = (
  list: (Source | undefined)[] | undefined,
  type: string
) => {
  return list && list.filter((item) => item && item.sourceType === type).length
}
