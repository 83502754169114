import React, { useState, useEffect, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Button from '../../components/Button'
import cropImage from '../../utils/libs/cropImage'
import flipImage from '../../utils/libs/flipImage'
import { rotateImage } from '../../utils/libs/rotateImage'
import useLoading from '../../hooks/useLoading'
import ZoomButtons from './ZoomButtons'
import {
  CropButtonWrapper,
  CropWrapper,
  FunctionWrapper,
  IconFunction,
  LoadingWrapper,
  SecondaryTypography,
  Wrapper,
  Container
} from './_stylesCropImage'
import { getFuctions } from './constants'

type SourceSizeType = {
  sourceWidth: number
  sourceHeight: number
}

type CropImageProps = {
  image?: string
  aspect?: number
  onCrop?(src: string): void
  restrictPosition?: boolean
  sourceSize: SourceSizeType
}

type ZoomPoint = {
  x: number
  y: number
}

type CroppedAreaPixelsPoint = {
  width: number
  height: number
  x: number
  y: number
}

const CropImage: React.FC<CropImageProps> = ({
  image: imageSrc = '',
  onCrop: handleCrop = () => {},
  sourceSize
}) => {
  const [image, setImage] = useState(imageSrc)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [isRotation, setIsRotation] = useState(false)
  const [isCropping, setIsCropping] = useState(false)
  const { startLoading, endLoading, renderLoading, isLoading } = useLoading()
  const [minZoom, setMinZoom] = useState<number | null>(null)
  const [isZoom, setIsZoom] = useState(false)
  const [cropWidth, setCropWidth] = useState(0)
  const [cropHeight, setCropHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [isWidthDropped, setIsWidthDropped] = useState(true)
  const disabled = !image || isCropping || isLoading

  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<CroppedAreaPixelsPoint | null>(null)
  const [aspect, setAspect] = useState(0)

  const cropArea = document.querySelector<HTMLElement>(
    '.reactEasyCrop_CropArea'
  )

  const handleCropComplete = useCallback(
    (_croppedArea, croppedAreaPixels) => {
      let newCroppedArea = croppedAreaPixels
      if (zoom < 1) {
        newCroppedArea = {
          width: croppedAreaPixels.width * (1 / zoom),
          height: croppedAreaPixels.height * (1 / zoom)
        }
        newCroppedArea.x = isWidthDropped
          ? (croppedAreaPixels.width - newCroppedArea.width) / 2
          : 0
        newCroppedArea.y = isWidthDropped
          ? 0
          : (croppedAreaPixels.height - newCroppedArea.height) / 2
      }
      setCroppedAreaPixels(newCroppedArea)
    },
    [zoom, isWidthDropped]
  )

  useEffect(() => {
    if (!sourceSize) return
    const { sourceWidth, sourceHeight } = sourceSize
    setAspect(sourceWidth / sourceHeight)
  }, [sourceSize])

  useEffect(() => {
    startLoading()
  }, [])

  useEffect(() => {
    if (!cropWidth || !cropHeight || !imageWidth || !imageHeight || isRotation)
      return
    let minZoom = 0.1
    const cropRatio = cropWidth / cropHeight
    const imageRatio = imageWidth / imageHeight
    if (cropRatio > imageRatio) {
      minZoom = cropHeight / imageHeight
      setIsWidthDropped(true)
    } else {
      minZoom = cropWidth / imageWidth
      setIsWidthDropped(false)
    }
    setMinZoom(minZoom + 0.01)
    endLoading()
  }, [cropWidth, cropHeight, imageWidth, imageHeight, isRotation, endLoading])

  useEffect(() => {
    if (cropArea) {
      setCropWidth(cropArea.clientWidth)
      setCropHeight(cropArea.clientHeight)
    }
  }, [cropArea, cropArea?.clientHeight, cropArea?.clientWidth])

  const handleComplete = useCallback(async () => {
    setIsCropping(true)
    try {
      const croppedImage = await cropImage(image, croppedAreaPixels, 0)
      handleCrop(croppedImage)
    } catch (e) {
      console.error(e)
    }
    setTimeout(() => {
      setIsCropping(false)
    }, 255)
  }, [croppedAreaPixels, handleCrop, image])

  const handleCropChange = (item: ZoomPoint) => {
    const { x, y } = item
    if (isZoom) {
      setCrop({ x: 0, y: 0 })
    }
    if (!isZoom && zoom >= 1) {
      setCrop({ x, y })
    }
  }

  const handleZoomChange = (item: number) => {
    if (!minZoom) return
    setIsZoom(true)
    if (item === zoom || (1 > item && zoom < minZoom)) return
    const plusZoom = Math.min(zoom + 0.2, 3)
    const minusZoom = Math.max(zoom - 0.2, minZoom)
    setZoom(item > zoom ? plusZoom : minusZoom)
    setIsZoom(false)
  }

  const handleFlipX = useCallback(async () => {
    const result = await flipImage(image, 'flipX')
    setImage(result)
    endLoading()
  }, [image])

  const handleFlipY = useCallback(async () => {
    const result = await flipImage(image, 'flipY')
    setImage(result)
    endLoading()
  }, [image])

  const handleRotate = useCallback(async () => {
    // 회전할 때마다 이미지 사이즈가 변경되어 minZoom을 다시 계산 => minZoom 값이 바뀌지 않도록
    setIsRotation(true)
    const result = await rotateImage(image, 90)
    setImage(result)
    endLoading()
  }, [endLoading, image])

  const functions = getFuctions(handleFlipX, handleFlipY, handleRotate)

  return (
    <Wrapper>
      <SecondaryTypography block style={{ marginBottom: 16 }}>
        불러온 사진을 사용할 영역만큼 자르거나 좌우/상하반전 또는 회전할 수
        있습니다.
        <br />
        이미지 확대와 축소는 마우스 스크롤로 조절할 수 있습니다.
      </SecondaryTypography>
      {isLoading && <LoadingWrapper>{renderLoading()}</LoadingWrapper>}
      <CropWrapper>
        <Container>
          <Cropper
            {...{ image, crop, zoom, aspect }}
            minZoom={minZoom || 1}
            onCropChange={handleCropChange}
            onZoomChange={handleZoomChange}
            onCropComplete={handleCropComplete}
            onMediaLoaded={() => {
              const imageArea = document.querySelector<HTMLImageElement>(
                '.reactEasyCrop_Contain'
              )
              if (imageArea) {
                setImageWidth(imageArea.clientWidth)
                setImageHeight(imageArea.clientHeight)
              }
            }}
          />
          <ZoomButtons
            onClickZoomIn={() => setZoom((prev) => Math.min(prev + 0.2, 3))}
            onClickZoomOut={() =>
              minZoom && setZoom((prev) => Math.max(prev - 0.2, minZoom))
            }
          />
        </Container>
      </CropWrapper>
      <FunctionWrapper>
        <div>
          {functions.map(({ icon, text, onClick }) => {
            return (
              <IconFunction
                key={text}
                disabled={disabled}
                icon={icon}
                onClick={() => {
                  startLoading()
                  onClick()
                }}
              >
                <SecondaryTypography bold>{text}</SecondaryTypography>
              </IconFunction>
            )
          })}
        </div>
      </FunctionWrapper>
      <CropButtonWrapper>
        <Button
          primary
          size='default'
          style={{ margin: 0 }}
          onClick={() => {
            startLoading()
            handleComplete()
          }}
          disabled={disabled}
        >
          {disabled ? '처리중' : '확인'}
        </Button>
      </CropButtonWrapper>
    </Wrapper>
  )
}

export default CropImage
