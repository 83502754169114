export const INPUT_SIZES = {
  SMALL: 'small',
  DEFAULT: 'default',
  LARGE: 'large'
} as const

export type InputSize = typeof INPUT_SIZES[keyof typeof INPUT_SIZES]

export const TEXT_ALIGNS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
} as const

export type TextAlign = typeof TEXT_ALIGNS[keyof typeof TEXT_ALIGNS]
