import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Cropper from 'react-easy-crop'
import Button from '../../components/Button'
import AspectRatioSizer from '../../components/AspectRatioSizer'
import Trimmer from './Trimmer'
import useLoading from '../../hooks/useLoading'
import ZoomButtons from './ZoomButtons'
import { Container } from './_stylesCropImage'
import Typography from '../../components/Typography'
import { CropData } from '../EditModals/VideoModal'

type CropVideoProps = {
  video: string
  thumbnailSrc?: string
  duration: number
  videoSize: { width: number; height: number }
  onComplete(data: CropData): void
}

const CropVideo: React.FC<CropVideoProps> = ({
  video: videoUrl,
  thumbnailSrc,
  duration,
  videoSize,
  onComplete: handleComplete
}) => {
  const cropperRef = useRef<any>(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [opacity, setOpacity] = useState(0)
  const { startLoading, endLoading, renderLoading, isLoading } = useLoading()
  const [computed, setComputed] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  })
  const [startTime, setStartTime] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [originalDuration, setOriginalDuration] = useState(10)
  const [aspect, setAspect] = useState(1)

  useEffect(() => {
    const { width, height } = videoSize
    setAspect(width / height)
  }, [videoSize])

  const handleVideoTimeUpdate = useCallback(() => {
    try {
      const video = cropperRef?.current?.videoRef
      if (video) {
        if (video.currentTime > startTime + duration) {
          video.currentTime = startTime
        }
        setCurrentTime(video.currentTime)
      }
    } catch (error) {
      console.log(error)
    }
  }, [startTime, duration])

  const handleCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setComputed(croppedAreaPixels)
  }, [])

  const handleTrimmerChange = useCallback((value) => {
    const video = cropperRef?.current?.videoRef
    setStartTime(value)
    setCurrentTime(value)
    video.currentTime = value
  }, [])

  useEffect(() => {
    const video: HTMLVideoElement = cropperRef?.current?.videoRef
    const handleLoadedMetaData = () => setOriginalDuration(video.duration)
    video.addEventListener('timeupdate', handleVideoTimeUpdate)
    video.addEventListener('loadedmetadata', handleLoadedMetaData)

    return () => {
      video.removeEventListener('timeupdate', handleVideoTimeUpdate)
      video.removeEventListener('loadedmetadata', handleLoadedMetaData)
    }
  }, [startTime, duration, videoUrl, handleVideoTimeUpdate])

  const firstPlay = () => {
    endLoading()
    setOpacity(1)
  }

  useEffect(() => {
    const video: HTMLVideoElement = cropperRef?.current?.videoRef
    video.addEventListener('play', firstPlay)
    return () => {
      video.removeEventListener('play', firstPlay)
    }
  }, [])

  useEffect(() => {
    startLoading()
    const video: HTMLVideoElement = cropperRef?.current?.videoRef
    if (video) (video as any).playsInline = true
  }, [])

  return (
    <Wrapper>
      {videoUrl && (
        <>
          <CropInfoTypography>
            불러온 영상을 사용할 영역만큼 자르고, <br />
            영상의 구간을 설정할 수 있습니다.
          </CropInfoTypography>
          <CropperWrapper>
            <AspectRatioSizer aspect={aspect} />
            <Container>
              {isLoading && <LoadingWrapper>{renderLoading()}</LoadingWrapper>}
              <Cropper
                {...{ crop, zoom, aspect }}
                ref={cropperRef}
                style={{ containerStyle: { opacity } }}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                video={videoUrl}
                onCropComplete={handleCropComplete}
              />
              <ZoomButtons
                onClickZoomIn={() => setZoom((prev) => Math.min(prev + 0.2, 3))}
                onClickZoomOut={() =>
                  setZoom((prev) => Math.max(prev - 0.2, 1))
                }
              />
            </Container>
          </CropperWrapper>
          <TrimmerTypography>비디오 삽입 구간 설정</TrimmerTypography>
          <Trimmer
            duration={duration}
            onChange={handleTrimmerChange}
            currentTime={currentTime}
            originalDuration={originalDuration}
            value={startTime}
            style={{ marginBottom: 16 }}
            thumbnailSrc={thumbnailSrc}
          />
          <div style={{ textAlign: 'right' }}>
            <Button
              primary
              disabled={isLoading}
              onClick={() =>
                handleComplete && handleComplete({ ...computed, startTime })
              }
            >
              완료
            </Button>
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default CropVideo

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`

const CropperWrapper = styled.div<{ aspect?: number }>`
  width: 100%;
  height: auto;
  max-height: 40vh;
  position: relative;
  margin-bottom: 16px;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

const CropInfoTypography = styled(Typography).attrs({
  type: 'body2',
  block: true,
  center: true
})`
  text-align: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

const TrimmerTypography = styled(Typography)`
  display: block;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
`
