export const basicColors = [
  '#ff0000',
  '#fd8b02',
  '#fec324',
  '#35c440',
  '#44abf7',
  '#1c47bc',
  '#9134e2',
  '#f77878',
  '#ffffff',
  '#121212'
]
