import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import 'antd/dist/antd.min.css'
import Typography from '../Typography'
import Button from '../Button'
import useDetectKeyboardOpen from '../../hooks/useDetectKeyboardOpen'
import FavColorTabs from './FavColorTabs'
import ColorChip from './ColorChip'
import {
  CanNotEdit,
  ColorPickerWrapper,
  ColorTitle,
  Container,
  Footer,
  HexColorInputWrapper,
  Preview,
  Row,
  SecondaryTypography,
  Wrapper
} from './styles'
import LoadingLottie from '../Lottie'

type ColorPanelProps = {
  isMutateLoading?: boolean
  defaultColor?: string
  currentColor?: string
  onColorChange?(newColor?: string, prevColor?: string): void
}

const ColorPanel = (props: ColorPanelProps) => {
  const {
    isMutateLoading,
    defaultColor: initialDefaultColor,
    currentColor: initialCurrentColor,
    onColorChange = () => {}
  } = props
  const theme = useTheme()
  const defaultColor =
    `#${initialCurrentColor}` || `#${initialDefaultColor}` || theme.colors.white

  const [color, setColor] = useState(defaultColor)
  const [favColor, setFavColor] = useState<string[]>(
    JSON.parse(sessionStorage.getItem('color') as string) || []
  )
  const isKeyboardOpen = useDetectKeyboardOpen(300)

  //NOTE: 색깔 즐겨찾기 로직
  const handleAddColor = () => {
    if (sessionStorage.getItem('color') !== null) {
      const prev = sessionStorage.getItem('color')
      const parsedColors: string[] = JSON.parse(prev as string)

      //NOTE: 색깔 중복 체크
      if (parsedColors.includes(color)) {
        return
      }
      //NOTE: 10개 넘어가면 앞에꺼 삭제
      if (parsedColors.length > 9) {
        parsedColors.pop()
      }

      parsedColors.unshift(color)
      sessionStorage.setItem('color', JSON.stringify(parsedColors))
      setFavColor(parsedColors)
    } else {
      sessionStorage.setItem('color', JSON.stringify([color]))
      setFavColor([color])
    }
  }

  const handleResetColor = () => {
    initialDefaultColor && setColor('#' + initialDefaultColor)
  }

  useEffect(() => {
    setColor(defaultColor)
  }, [defaultColor])

  return (
    <Wrapper>
      {!initialDefaultColor && (
        <CanNotEdit>
          <Typography
            type='body2'
            style={{ color: theme.colors.text.secondary }}
          >
            색상을 변경할 수 없는 템플릿입니다.
          </Typography>
        </CanNotEdit>
      )}
      {isMutateLoading ? (
        <LoadingLottie />
      ) : (
        initialDefaultColor && (
          <Container>
            <div>
              <Row>
                <div>
                  <Typography type='body2' bold>
                    색상
                  </Typography>
                </div>
                <div>
                  <Button
                    link
                    style={{ fontWeight: 400 }}
                    onClick={handleResetColor}
                  >
                    <Typography type='body2' bold>
                      기존 색상으로
                    </Typography>
                  </Button>
                </div>
              </Row>
              <Preview>
                <div>
                  <ColorTitle type='body2' bold>
                    기존 색상
                  </ColorTitle>
                  <br />
                  <ColorChip color={defaultColor} />
                  <br />
                  <SecondaryTypography type='body2'>
                    {defaultColor.toUpperCase()}
                  </SecondaryTypography>
                </div>
                <div style={{ flex: 'unset' }}>▶</div>
                <div>
                  <ColorTitle type='body2' bold>
                    변경 색상
                  </ColorTitle>
                  <br />
                  <ColorChip color={color} setColor={setColor} />
                  <br />
                  <SecondaryTypography type='body2'>
                    {color.toUpperCase()}
                  </SecondaryTypography>
                </div>
              </Preview>
              <Row>
                <div>
                  <Typography type='body2' bold>
                    색상 선택하기
                  </Typography>
                </div>
              </Row>
              <Row
                style={{
                  flexDirection: 'column',
                  paddingTop: 0,
                  paddingBottom: 0
                }}
              >
                <ColorPickerWrapper>
                  <HexColorPicker color={color} onChange={setColor} />
                </ColorPickerWrapper>
              </Row>
              <Row>
                <div>
                  <ColorChip color={color} />
                </div>
                <HexColorInputWrapper>
                  <HexColorInput prefixed color={color} onChange={setColor} />
                </HexColorInputWrapper>
                <div>
                  <Button
                    onClick={handleAddColor}
                    link
                    size='small'
                    style={{ fontWeight: 400 }}
                  >
                    즐겨찾는 색상 추가
                  </Button>
                </div>
              </Row>
              <FavColorTabs setColor={setColor} favColor={favColor} />
            </div>
            <Footer>
              <Button
                onClick={() => {
                  onColorChange(color.substring(1), defaultColor)
                }}
              >
                변경하기
              </Button>
            </Footer>
          </Container>
        )
      )}

      {isKeyboardOpen && <div style={{ height: 300 }} />}
    </Wrapper>
  )
}

export default ColorPanel
