import { useCallback, useEffect, useState } from 'react'

import { useModal } from '../../components/Modal'
import FFmpegService from '../../utils/libs/ffmpeg'

import GlobalStyles from '../../styles/GlobalStyles'
import Header from '../Header'
import Sidebar from '../Sidebar'
import Screen from '../Screen'

import 'react-color-palette/lib/css/styles.css'
import PreviewModal from '../PreviewModal'
import SceneList from '../SceneList/SceneList'
import ProgressBar from '../ProgressBar'
import TopContent from '../TopContent'
import RenderModal from '../RenderModal'
import EditModals from '../EditModals'
import {
  CenterWrapper,
  Content,
  Layout,
  MainContainer,
  ScreenWrapper,
  Wrapper
} from './_stylesMain'
import useLocalStorage from '../../hooks/useLocalStorage'
import { Auth, AuthInitialValue } from '../../components/AuthHelper'
import FirstModal from '../NotificationModals/FirstModal'
import BasicModal from '../NotificationModals/BasicModal'

interface MainProps {
  projectId: string
  needCheck?: boolean
  renderCnt?: number
}

const Main = ({ projectId }: MainProps) => {
  const [auth] = useLocalStorage<Auth>(
    'vplateVideoEditorAuth',
    AuthInitialValue
  )
  const isFirst = auth?.isFirst || false
  const maxCount = 5

  const [selectedSceneIndex, setSelectedSceneIndex] = useState(0)
  // (완료한 프로젝트) 수정 버튼을 누르고 아무것도 수정되지 않을 경우 렌더링 X
  const [isModified, setIsModified] = useState(false)
  const [isFlickering, setIsFlickering] = useState(false)
  const [processingSources, setProcessingSources] = useState<
    { sceneIndex: number; sourceIndex: number }[]
  >([])
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(-1)
  const renderModal = useModal()
  const previewModal = useModal()
  const editModal = useModal()
  const firstModal = useModal()
  const basicModal = useModal()

  const sendMessageToParent = useCallback((obj) => {
    if (typeof window?.parent?.postMessage === 'function') {
      window.parent.postMessage(JSON.stringify(obj), '*')
    }
  }, [])

  const isSourceProcessing = useCallback(
    (sceneIndex: number, sourceIndex: number) => {
      return !!processingSources.find(
        (item) =>
          item.sceneIndex === sceneIndex && item.sourceIndex === sourceIndex
      )
    },
    [processingSources]
  )

  const onSourceClick = useCallback(
    (index) => {
      if (!isSourceProcessing(selectedSceneIndex, index)) {
        setSelectedSourceIndex(index)
        editModal.open()
      }
    },
    [editModal, isSourceProcessing, selectedSceneIndex]
  )

  useEffect(() => {
    if (isFirst) {
      firstModal.open()
    }
  }, [isFirst])

  useEffect(() => {
    FFmpegService.init()
    return () => {
      FFmpegService.ffmpeg.exit()
    }
  }, [])

  return (
    <Wrapper className='vplateVideoEditorWrapper'>
      <GlobalStyles />
      <Header
        isModified={isModified}
        onCompleteClick={() => renderModal.open()}
        setSelectedSceneIndex={setSelectedSceneIndex}
        setIsFlickering={setIsFlickering}
        sendMessageToParent={sendMessageToParent}
        maxCount={maxCount}
        basicModal={basicModal}
      />
      <Layout>
        <Content>
          <MainContainer>
            <TopContent previewModal={previewModal} />
            <ProgressBar previewModal={previewModal} />
            <ScreenWrapper>
              <Screen
                selectedSceneIndex={selectedSceneIndex}
                isSourceProcessing={isSourceProcessing}
                isFlickering={isFlickering}
                onSourceClick={onSourceClick}
              />
              <SceneList
                selectedSceneIndex={selectedSceneIndex}
                onSceneChange={(index) => {
                  setSelectedSceneIndex(index)
                  setIsFlickering(false)
                }}
              />
            </ScreenWrapper>
          </MainContainer>
        </Content>
      </Layout>
      <Sidebar setIsModified={setIsModified} />
      <EditModals
        editModal={editModal}
        selectedSourceIndex={selectedSourceIndex}
        selectedSceneIndex={selectedSceneIndex}
        setProcessingSources={setProcessingSources}
        setIsModified={setIsModified}
      />
      {renderModal.visible && (
        <RenderModal
          projectId={projectId}
          renderModal={renderModal}
          sendMessageToParent={sendMessageToParent}
          maxCount={maxCount}
        />
      )}
      {previewModal.visible && (
        <CenterWrapper>
          <PreviewModal
            onClose={() => previewModal.close()}
            url={`https://api.vplate.io/v3/subtemplate/${projectId}/preview?previewFrame=${selectedSceneIndex}`}
          />
        </CenterWrapper>
      )}
      {firstModal.visible && <FirstModal onClose={() => firstModal.close()} />}
      {basicModal.visible && (
        <BasicModal
          sendMessageToParent={sendMessageToParent}
          onClose={() => basicModal.close()}
        />
      )}
    </Wrapper>
  )
}

export default Main
