import styled from 'styled-components'
import {
  blockCss,
  body1Css,
  body2Css,
  boldCss,
  caption1Css,
  caption2Css,
  centerCss,
  defaultCss,
  headlineCss,
  title1Css,
  title2Css
} from './styles'

type TypographyProps = {
  type?:
    | 'default'
    | 'headline'
    | 'title1'
    | 'title2'
    | 'body1'
    | 'body2'
    | 'caption1'
    | 'caption2'
  bold?: boolean
  block?: boolean
  center?: boolean
}

const Typography = styled.span<TypographyProps>`
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.3;
  justify-content: flex-start;
  align-items: center;

  ${({ type }) => {
    switch (type) {
      case 'headline':
        return headlineCss
      case 'title1':
        return title1Css
      case 'title2':
        return title2Css
      case 'body1':
        return body1Css
      case 'body2':
        return body2Css
      case 'caption1':
        return caption1Css
      case 'caption2':
        return caption2Css
      default:
        return defaultCss
    }
  }}

  ${({ bold }) => bold && boldCss}

  ${({ block }) => block && blockCss}

  ${({ center }) => center && centerCss}
`

export default Typography
