import styled, { css } from 'styled-components'
import useBreakpoint from '../../hooks/useBreakpoint'
import { getCountItems } from './constants'

type Numbers = {
  required?: number
  current?: number
}

export interface CountProps {
  duration?: number
  text?: Numbers
  video?: Numbers
  photo?: Numbers
}

const Count = (props: {
  duration?: number
  text?: Numbers
  video?: Numbers
  photo?: Numbers
}) => {
  const { duration = 0, text, video, photo } = props
  const { isBreakpoint } = useBreakpoint()

  return (
    <Wrapper>
      {getCountItems({ duration, text, video, photo }).map(
        ({ icon, title, content }) => (
          <div key={title}>
            {icon}
            {!isBreakpoint('small') && <Title>{title}</Title>}
            <Text bold>{content}</Text>
          </div>
        )
      )}
    </Wrapper>
  )
}

export default Count

const Text = styled.span<{ bold?: boolean }>`
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.3;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.9em;
  font-weight: ${(props) => (props.bold ? 700 : 'normal')};
`

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-right: 5px;
`

const Wrapper = styled.div`
  height: auto;
  position: relative;
  padding: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      gap: 24px;
    `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 8px;
    `)}
    
  ${({ theme }) =>
    theme.breakpoints.small(css`
      gap: 4px;
    `)}

  & > div {
    display: inline-block;

    & > svg {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
`
