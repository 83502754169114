export const enum Platform {
  Vplate = 'vplate',
  VplateDev = 'vplateDev'
}

export const getApiUrl = (platform: Platform) => {
  switch (platform) {
    case Platform.Vplate:
      return process.env.REACT_APP_API_URL
    case Platform.VplateDev:
      return process.env.REACT_APP_DEV_API_URL
    default:
      return process.env.REACT_APP_DEV_API_URL
  }
}
