import { useCallback, useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import { message } from 'antd'
import 'antd/dist/antd.min.css'
import styled, { css } from 'styled-components'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import useLoading from '../../hooks/useLoading'
interface PreviewModalProps {
  onClose: () => void
  url: string
}

const PreviewModal = ({ onClose, url }: PreviewModalProps) => {
  const [showImage, setShowImage] = useState(false)
  const [blobUrl, setBlobUrl] = useState('')
  const { renderLoading, startLoading, endLoading } = useLoading()
  const screens = useBreakpoint()
  const handleLoadImage = () => {
    endLoading()
    setShowImage(true)
  }
  useEffect(() => {
    setShowImage(false)
  }, [])

  const handleClose = useCallback(() => {
    showImage && onClose()
  }, [showImage])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showImage) {
        onClose()
        message.error(
          '미리보기 기능에 오류가 생겼습니다. 잠시 후에 다시 시도해주세요.'
        )
      }
    }, 60000)
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [showImage])

  const fetchUrl = async (dataUrl: RequestInfo | URL) => {
    const blob = await (await fetch(dataUrl)).blob()
    const result = URL.createObjectURL(blob)
    setBlobUrl(result)
  }

  useEffect(() => {
    if (url) {
      fetchUrl((process.env.REACT_APP_CORS_PROXY_URL || '') + url)
    }
  }, [url])

  useEffect(() => {
    startLoading()
  }, [])

  return (
    <Modal onClose={handleClose} visible style={modalStyle} title='미리보기'>
      <Container>
        <LoadingWrapper>{renderLoading()}</LoadingWrapper>
        {
          <Flex>
            {blobUrl && (
              <Image
                src={blobUrl}
                alt='미리보기'
                onLoad={handleLoadImage}
                show={showImage}
                xs={screens.xs}
              />
            )}
          </Flex>
        }
      </Container>
    </Modal>
  )
}

export default PreviewModal

const LoadingWrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled(Flex)`
  padding: 0px 50px;
`

const Image = styled.img`
  ${({ show, xs }: { show: boolean; xs: boolean | undefined }) =>
    show
      ? `
        visibility: visible;
        width: auto;
        max-height: ${xs ? 300 : 550}px;
      `
      : `visibility: hidden;
  `}
`

const modalStyle = css`
  margin: 0 auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
  min-height: fit-content;
  overflow: hidden;
`
