import {
  IconDanger,
  IconInfo,
  IconSuccess,
  IconWarning
} from '../../assets/icons'

interface ConvertProps {
  url: string
  noProxy: boolean
}

export const convertURLtoFile = async ({ url, noProxy }: ConvertProps) => {
  const response = await fetch(
    (!noProxy ? process.env.REACT_APP_CORS_PROXY_URL : '') + url
  )
  const data = await response.blob()
  const ext = url.split('.').pop() // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop() // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` }
  return new File([data], filename!, metadata)
}

export const getDuration = (file: File) =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.src = URL.createObjectURL(file)

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        resolve(video.duration)
      }

      video.onerror = (error) => reject(error)
    } catch (error) {
      reject(error)
    }
  })

export const getInfoByLength = (
  length: number,
  textLength: number,
  size = 13
) => {
  if (length <= 0) {
    return {
      icon: <IconInfo width={size} height={size} />,
      text: '이목을 집중시킬 텍스트로 입력하면 좋아요'
    }
  }
  if (length > 0 && length <= textLength) {
    return {
      icon: <IconSuccess width={size} height={size} />,
      text: '템플릿에 잘 어울려요'
    }
  }
  if (length > textLength && length <= 50) {
    return {
      icon: <IconWarning width={size} height={size} />,
      text: '템플릿에 어울리지 않을 수 있어요'
    }
  }
  if (length > 50) {
    return {
      icon: <IconDanger width={size} height={size} />,
      text: '템플릿에 어울리지 않아요'
    }
  }
  return null
}
