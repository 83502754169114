import { DefaultTheme, StyledComponentProps } from 'styled-components'

import LoadingLottie from '../Lottie'
import { ButtonCompo } from './styles'

export type ButtonProps = {
  primary?: boolean
  round?: boolean
  link?: boolean
  secondary?: boolean
  block?: boolean
  size?: 'small' | 'default' | 'large'
  loading?: boolean
}

const Button = ({
  children,
  loading = false,
  ...props
}: StyledComponentProps<'button', DefaultTheme, ButtonProps, never>) => {
  return (
    <ButtonCompo {...props}>
      {loading ? <LoadingLottie width={15} height={15} /> : children}
    </ButtonCompo>
  )
}

export default Button
