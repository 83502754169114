import { Row } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import ColorChip from './ColorChip'

interface ColorChipsProps {
  colors: string[]
  setColor?: Dispatch<SetStateAction<string>>
}

const ColorChips = ({ colors, setColor }: ColorChipsProps) => {
  const chips = colors.map((color) => (
    <ColorChip key={color} setColor={setColor} color={color} />
  ))

  return <Row style={{ minHeight: '40px', gap: '24px' }}>{chips}</Row>
}

export default ColorChips
