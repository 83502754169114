import { css } from 'styled-components'

export const defaultCss = css`
  font-size: 1em;
`

export const body1Css = defaultCss

export const body2Css = css`
  font-size: 0.85em;
`

export const title1Css = css`
  font-size: 1.5em;
`

export const title2Css = css`
  font-size: 1.3em;
`

export const headlineCss = css`
  font-size: 1.75em;
`

export const caption1Css = css`
  font-size: 0.75em;
`

export const caption2Css = css`
  font-size: 0.65em;
`

export const boldCss = css`
  font-weight: 700;
`

export const blockCss = css`
  display: flex;
`

export const centerCss = css`
  text-align: center;
  justify-content: center;
`
