import React, { useCallback, useEffect, useState } from 'react'

import Button from '../../components/Button'
import { ReactComponent as IconEdit } from '../../assets/icons/icon_editor_edit_filled_normal_gray.svg'
import useBreakpoint from '../../hooks/useBreakpoint'
import { message } from 'antd'
import { Project } from '../../types/project'
import checkXSS from '../../utils/libs/checkXSS'
import theme from '../../styles/theme'
import { getStatusText, getTooltipMessage, warningMessage } from './constants'
import { useProjectContext } from '../../hooks/useProject'
import {
  BtnEdit,
  ButtonWrapper,
  Container,
  GoBackButtonWrapper,
  StyledInput,
  StyledTypography,
  Wrapper
} from './_stylesHeader'
import { service } from '../../utils/api'
import Tooltip from '../../components/Tooltip'
import { UseModalProps } from '../../types/useModal'

message.config({ maxCount: 3 })

interface HeaderProps {
  isModified: boolean
  onCompleteClick?(): void
  setSelectedSceneIndex: React.Dispatch<React.SetStateAction<number>>
  setIsFlickering: React.Dispatch<React.SetStateAction<boolean>>
  sendMessageToParent: (obj: { code: string; data?: string | Project }) => void
  maxCount: number
  basicModal: UseModalProps
}

const Header = ({
  isModified,
  onCompleteClick,
  setSelectedSceneIndex,
  setIsFlickering,
  sendMessageToParent,
  maxCount,
  basicModal
}: HeaderProps) => {
  const { project, setProject, sceneList, projectId, needCheck, renderCnt } =
    useProjectContext()
  const isLimit = needCheck
    ? renderCnt !== undefined && renderCnt >= maxCount
    : false
  const { title, workProgress, renderProgress, status = 0 } = project || {}
  const { isBreakpoint } = useBreakpoint()
  const [showInput, setShowInput] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const disabled =
    isLimit || status === 1 || status === 2 || (status >= 3 && !isModified)

  const statusText = getStatusText(
    status,
    renderProgress,
    needCheck,
    renderCnt,
    maxCount
  )

  const onChangeInput = useCallback((event) => {
    const value = event.currentTarget.value
    const result = checkXSS(value)

    if (result.length !== value.length) {
      message.warning({
        content: warningMessage,
        duration: 0.3
      })
      return
    }
    setInputValue(result)
  }, [])

  const onRenameProject = async (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    setShowInput(false)
    if (!inputValue || inputValue === title) return
    const { value } = e.currentTarget
    const result = { ...project, title: checkXSS(value) } as Project
    try {
      setProject && (await setProject(result))
    } catch (error) {
      if (error instanceof Error) {
        setInputValue(title || '')
        message.error(error.message)
      }
    }
  }

  const onGoBackClick = () => {
    if (status && status >= 3 && !isModified) {
      sendMessageToParent({ code: 'PROJECT_DATA', data: project })
      return
    }
    sendMessageToParent({ code: 'ROUTE', data: '/projects' })
  }

  const handleNotComplete = useCallback(() => {
    const notCompleteSceneIndex = (sceneList || []).findIndex(
      ({ isCompleted }) => !isCompleted
    )
    message.warning(`${notCompleteSceneIndex + 1}번째 씬을 마저 작업해주세요.`)
    setSelectedSceneIndex(notCompleteSceneIndex)
    setIsFlickering(true)
  }, [sceneList, setIsFlickering, setSelectedSceneIndex])

  const handleOnclick = () => {
    if (workProgress !== undefined && workProgress < 100) {
      handleNotComplete()
      return
    }
    onCompleteClick && onCompleteClick()
  }

  // 완료된 프로젝트여도 수정한 부분이 있으면 status 변경
  const changeStatusProject = useCallback(async () => {
    if (!projectId) return
    await service().projects.changeStatus(projectId, 0)
    const result = { ...project, status: 0 } as Project
    setProject && setProject(result)
  }, [projectId, project, setProject])

  useEffect(() => {
    if (project?.status && project.status >= 3 && isModified) {
      changeStatusProject()
    }
  }, [project?.status, isModified, changeStatusProject])

  useEffect(() => {
    if (title) {
      setInputValue(title)
    }
  }, [title])

  useEffect(() => {
    if (isLimit) {
      basicModal.open()
    }
  }, [isLimit])

  return (
    <Wrapper>
      <GoBackButtonWrapper>
        <Button link onClick={onGoBackClick}>
          ＜&nbsp;{!isBreakpoint('medium') && '나의 프로젝트'}
        </Button>
      </GoBackButtonWrapper>
      <Container>
        <div>
          {!showInput ? (
            <>
              <StyledTypography bold>{title}</StyledTypography>
              <IconEdit
                style={{ marginLeft: 4, cursor: 'pointer' }}
                onClick={() => setShowInput(true)}
              />
            </>
          ) : (
            <StyledInput
              value={inputValue}
              autoFocus
              maxLength={20}
              onBlur={onRenameProject}
              onPressEnter={onRenameProject}
              onChange={onChangeInput}
              style={{ borderColor: theme.colors.primary }}
            />
          )}
        </div>
        <ButtonWrapper>
          {workProgress !== undefined && (
            <Tooltip
              position='left'
              title={getTooltipMessage(status, isModified, isLimit)}
              touch
            >
              <div style={{ width: 'fit-content' }}>
                <BtnEdit
                  size={isBreakpoint('small') ? 'small' : 'default'}
                  primary
                  disabled={disabled}
                  onClick={handleOnclick}
                >
                  {statusText}
                </BtnEdit>
              </div>
            </Tooltip>
          )}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  )
}

export default Header
