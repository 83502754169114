import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'

import AuthHelper from './components/AuthHelper'
import App from './App'

import { datadogRum } from '@datadog/browser-rum'

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '11aefb41-74ff-4da7-9c98-8f48b9eaedd2',
    clientToken: 'pub0c43c959565e72996f98818feb7f6d87',
    site: 'datadoghq.com',
    service: 'vplate-editor',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    trackUserInteractions: true,
    enableExperimentalFeatures: ['clickmap', 'feature_flags']
  })

  datadogRum.onReady(() => {
    datadogRum.startView()
    datadogRum.startSessionReplayRecording()
  })
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthHelper>
        <App />
      </AuthHelper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
