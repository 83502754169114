import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ProjectContext, useProject } from '../hooks/useProject'

import theme from '../styles/theme'
import Main from './Main'

const Editor = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const { project, setProject, sceneList, isMutateLoading } =
    useProject(projectId)

  return (
    <ThemeProvider theme={theme}>
      <ProjectContext.Provider
        value={{
          project,
          setProject,
          sceneList,
          projectId,
          needCheck: project?.needCheck,
          renderCnt: project?.renderCnt,
          isMutateLoading
        }}
      >
        <Main projectId={projectId} />
      </ProjectContext.Provider>
    </ThemeProvider>
  )
}

export default Editor
