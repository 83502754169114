import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

interface ColorChipProps {
  color: string
  setColor?: Dispatch<SetStateAction<string>>
}

const ColorChip = ({ color, setColor }: ColorChipProps) => {
  const handleClick = () => {
    setColor && setColor(color)
  }

  return <Chip color={color} onClick={handleClick} />
}

export default ColorChip

const Chip = styled.button<{ color: string }>`
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;
  background: transparent;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  border: 1px solid ${({ theme }) => theme.colors.border};
`
