import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import Button from '../Button'
import { DRAWER_CLASSNAMES, DRAWER_POSITIONS } from './constants'

export type DrawerProps = {
  style?: React.CSSProperties
  title?: React.ReactNode | string
  closable?: boolean
  position?: 'right' | 'left' | 'top' | 'bottom'
  visible?: boolean
  onClose?(): void
  isChildDrawerOpen?: boolean
  backButton?: string | React.ReactNode
  onGoBack?(): void
}

const Drawer: React.FC<DrawerProps> = ({
  style,
  children,
  title,
  position = DRAWER_POSITIONS.LEFT,
  visible,
  onClose,
  isChildDrawerOpen,
  backButton,
  onGoBack
}) => {
  const nodeRef = useRef(null)
  useEffect(() => {
    window.document
      .querySelectorAll(`.${DRAWER_CLASSNAMES.VISIBLE}`)
      .forEach((el) => {
        if (el) {
          if (el.querySelectorAll(`.${DRAWER_CLASSNAMES.VISIBLE}`).length > 0) {
            el.classList.add(DRAWER_CLASSNAMES.HAS_CHILD)
            return
          }
          el.classList.remove(DRAWER_CLASSNAMES.HAS_CHILD)
        }
      })
  }, [visible])

  return (
    <>
      <Outside visible={visible} onClick={() => onClose && onClose()} />
      <CSSTransition
        nodeRef={nodeRef}
        in={visible}
        timeout={300}
        unmountOnExit
        classNames={`${DRAWER_CLASSNAMES.TRANSITION}-${position}`}
      >
        <Wrapper
          ref={nodeRef}
          style={style}
          className={
            visible ? DRAWER_CLASSNAMES.VISIBLE : DRAWER_CLASSNAMES.INVISIBLE
          }
          position={position}
          visible={visible}
          isChildDrawerOpen={isChildDrawerOpen}
        >
          {title && (
            <Title>
              {backButton && (
                <div
                  style={{
                    left: 0,
                    position: 'absolute'
                  }}
                >
                  <Button link onClick={onGoBack}>
                    {backButton}
                  </Button>
                </div>
              )}
              {title}
            </Title>
          )}
          <Content>{children}</Content>
        </Wrapper>
      </CSSTransition>
    </>
  )
}

export default Drawer

const Wrapper = styled.div<DrawerProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden !important;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      height: 100%;
      @supports (-webkit-touch-callout: none) {
        overflow: scroll !important;
      }
    `)}

  ${({ position }) => {
    switch (position) {
      case DRAWER_POSITIONS.TOP:
        return css`
          left: 0;
          top: 0;
          max-height: 364px;
        `
      case DRAWER_POSITIONS.RIGHT:
        return css`
          right: 0;
          top: 0;
          max-width: 364px;
        `
      case DRAWER_POSITIONS.BOTTOM:
        return css`
          left: 0;
          bottom: 0;
          max-height: 364px;
        `
      case DRAWER_POSITIONS.LEFT:
      default:
        return css`
          left: 0;
          top: 0;
          max-width: 364px;
        `
    }
  }}
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;

  z-index: 200;
  overflow-y: auto;

  ${({ isChildDrawerOpen }) =>
    isChildDrawerOpen &&
    css`
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: hidden;
    `}

  &.drawer--has-child {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      max-height: unset;
      min-height: unset;
    `)}
`

const Title = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.border};
  user-select: none;
  font-weight: 700;
`

const Content = styled.div`
  width: 100%;
  overflow: auto;
  position: relative;
  flex: 1;
`

const Outside = styled.div<DrawerProps>`
  width: 100%;
  height: 70%;
  position: fixed;
  left: 0;
  top: 30%;
  z-index: 190;
  background-color: transparent;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`
