import styled, { css } from 'styled-components'
import Button from '../Button'
import theme from '../../styles/theme'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
      }
      height: 100vh;
      height: fill-available;
    `)}
`

export const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
  margin: 0 auto;
  overflow: ${({ isMobile }) =>
    isMobile ? 'hidden !important' : 'auto !important'};
  touch-action: none;
  overscroll-behavior-y: none;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      max-width: 360px;
    `)}
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      max-width: unset;
    `)}
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  gap: 8px;
`

export const Col = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
`

export const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  font-weight: 400;
  color: ${theme.colors.text.primary};
  text-align: justify;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledCol = styled(Col)<{ isMobile: boolean }>`
  min-height: ${({ isMobile }) => (isMobile ? 'unset' : '15vh')};
  overflow: auto;
  flex: 1;
`

export const BreakAllContainer = styled.div`
  width: 100%;
  word-break: break-all;
  flex: 1;
`

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  border-bottom: 1px solid ${theme.colors.border};
`
export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4px;
  padding: 8px;
`

const HEIGHT = 48
export const RenderTrackContainer = styled.div<{
  background: string | undefined
}>`
  width: 100%;
  height: ${HEIGHT}px;
  position: relative;
  background: ${theme.colors.border};
  background-image: ${({ background }) => background && `url('${background}')`};
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

export const RenderThumbContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: ${HEIGHT}px;
  border: 1px solid ${theme.colors.primary};
  background: ${theme.colors.primary}55;
`

export const LottieContainer = styled.div`
  margin-top: 20px;
`

export const FilterButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`
