import {
  iconReverseHorizontal,
  iconReverseVertical,
  iconRotate
} from '../../assets/icons'

export const getFuctions = (
  handleFlipX: () => void,
  handleFlipY: () => void,
  handleRotate: () => Promise<void>
) => [
  {
    icon: iconReverseHorizontal,
    text: '좌우반전',
    onClick: handleFlipX
  },
  {
    icon: iconReverseVertical,
    text: '상하반전',
    onClick: handleFlipY
  },
  {
    icon: iconRotate,
    text: '회전',
    onClick: handleRotate
  }
]
