const colors = {
  primary: '#e54948',
  secondary: '#9134e2',
  white: '#ffffff',
  black: '#333333',
  button: {
    disabled: '#f2f2f2'
  },
  text: {
    primary: '#333333',
    secondary: '#666666',
    disabled: '#cccccc',
    link: '#888888'
  },
  input: {
    disabled: '#F1F1F1'
  },
  complementary: '#48cde5',
  analogous: '#ffe8e8',
  danger: '#ed0505',
  warning: '#fec324',
  success: '#35c440',
  info: '#44abf7',
  dark: '#131313',
  border: '#f2f2f2',
  backgroundEditor: '#fafafa',
  grey: '#00000080',
  lightPink: '#F7EBEB'
}

export type Colors = typeof colors
export default colors
