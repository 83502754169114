import React from 'react'
import styled, { keyframes, css } from 'styled-components'

interface LoadingIndicatorProps {
  style?: React.CSSProperties
}

const LoadingIndicator = ({ style }: LoadingIndicatorProps) => (
  <LoadingWrapper style={style}>
    <LoadingCircle></LoadingCircle>
    <LoadingCircle></LoadingCircle>
    <LoadingCircle></LoadingCircle>
    <LoadingCircle></LoadingCircle>
  </LoadingWrapper>
)

export default LoadingIndicator

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoadingWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`

const LoadingCircle = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 5px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  animation: ${() =>
    css`
      ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    `};
  border-color: #f00 transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`
