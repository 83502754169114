import { Context, createContext, useContext, useMemo } from 'react'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { fetchProject, patchProject } from '../Editor/constants'
import { Project } from '../types/project'
import { Source2 } from '../types/template'

export type SetProject = UseMutateAsyncFunction<
  Project | undefined,
  unknown,
  Project | undefined,
  unknown
>

interface ProjectContextType {
  project?: Project
  setProject?: SetProject
  isMutateLoading?: boolean
  sceneList?: (Source2 & { isCompleted?: boolean })[]
  projectId?: string
  needCheck?: boolean
  renderCnt?: number
}

export const ProjectContext: Context<ProjectContextType> = createContext({})

export const useProject = (projectId: string) => {
  const queryClient = useQueryClient()

  const { data: project } = useQuery(
    ['project', projectId],
    () => fetchProject(projectId),
    {
      staleTime: Infinity,
      cacheTime: Infinity
    }
  )

  const { mutateAsync: setProject, isLoading } = useMutation(
    (_project?: Project) => patchProject(_project),
    {
      onSuccess: (updatedProject) => {
        queryClient.setQueryData(['project', projectId], updatedProject)
      }
    }
  )

  const sceneList = useMemo(() => {
    const { design, sources } = project ?? {}
    return (design?.sources || []).map((scene, index) => ({
      ...scene,
      isCompleted: (sources?.[index]?.filter((item) => !item).length || 0) <= 0
    }))
  }, [project])

  return { project, setProject, isMutateLoading: isLoading, sceneList }
}

export const useProjectContext = () => useContext(ProjectContext)
