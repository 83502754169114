import Axios from 'axios'
import queryString from 'querystring'

const { create } = Axios

const pexelsAxios = create({
  baseURL: 'https://api.pexels.com',
  withCredentials: false
})

pexelsAxios.defaults.headers = {
  Authorization: process.env.REACT_APP_PEXELS_KEY
}
interface SearchVideosParam {
  query: string
  page: number
  limit: number
  from: string
  minDuration: number
}
interface SearchPhotosParam {
  query?: string
  page?: number
  limit?: number
  per_page?: number
  orientation?: string
  from: string
}

export interface PhotoData {
  thumbnail: string
  image: string
  width: number
  height: number
  from: string
  link: string
  id: number
}
export interface VideoData {
  from: string
  link: string
  thumbnail: string
  video: string
  width: number
}

export interface ResultVideoProps {
  total: number
  limit: number
  page: number
  totalPage: number
  next_page?: string
  data: VideoData[]
}
export interface ResultPhotoProps {
  total: number
  limit: number
  page: number
  totalPage: number
  next_page?: string
  data: PhotoData[]
}

function api() {
  const searchVideos = async (
    param: SearchVideosParam = {
      query: '',
      page: 1,
      limit: 20,
      from: 'pixabay',
      minDuration: 0
    }
  ) => {
    const { query, from } = param
    let { page, limit, minDuration } = param
    if (!page) {
      page = 1
    }
    if (!limit) {
      limit = 20
    }
    if (!minDuration || Number.isNaN(minDuration)) {
      minDuration = 0
    }

    const pixabayParams = {
      key: process.env.REACT_APP_PIXABAY_KEY,
      per_page: limit,
      page
    }
    if (query) {
      pixabayParams['q'] = query
    } else {
      pixabayParams['editors_choice'] = true
    }

    let result: any = {}
    const resultToReturn: ResultVideoProps = {
      total: 0,
      limit,
      page,
      totalPage: 0,
      data: []
    }
    switch (from) {
      case 'pexels':
        result = await pexelsAxios({
          url:
            (query ? '/videos/search?' : '/videos/popular?') +
            queryString.stringify({
              per_page: limit,
              query,
              page,
              minDuration: minDuration
            }),
          method: 'get'
        })
        if (Array.isArray(result?.data?.videos)) {
          resultToReturn.next_page = result.data.next_page
          resultToReturn.total = result.data.total_results
          resultToReturn.totalPage = Math.ceil(result.data.total / limit)
          resultToReturn.data = result.data.videos
            .filter(({ duration }: any) => {
              return parseInt(duration, 10) >= minDuration
            })
            .map(({ image: thumbnail, video_files: videoFiles, url }: any) => {
              const video = (videoFiles || []).find(
                (item: { width: string; height: string }) => {
                  return (
                    parseInt(item?.width, 10) === 1920 ||
                    parseInt(item?.height, 10) === 1080
                  )
                }
              )
              return {
                thumbnail,
                // video: videoFiles?.[0]?.link,
                video: video?.link,
                width: video?.width,
                height: video?.height,
                from,
                link: url || 'https://pexels.com'
              }
            })
        }
        break
      case 'pixabay':
      default:
        result = await Axios.get(`https://pixabay.com/api/videos/`, {
          params: pixabayParams
        })
        resultToReturn.total = result.data.total
        resultToReturn.totalPage = Math.ceil(result.data.total / limit)
        resultToReturn.data = result.data.hits
          .filter(({ duration }: any) => {
            return parseInt(duration, 10) >= minDuration
          })
          .map(({ picture_id: pictureId, videos, pageURL }: any) => ({
            thumbnail: `https://i.vimeocdn.com/video/${pictureId}.jpg`,
            video: videos?.large?.url,
            width: videos?.large?.width,
            height: videos?.large?.height,
            from,
            link: pageURL || 'https://pixabay.com'
          }))
        break
    }

    return resultToReturn
  }
  const searchPhotos = async (param: SearchPhotosParam) => {
    const { query, from } = param
    let { page, limit, orientation } = param
    if (!page) {
      page = 1
    }
    if (!limit) {
      limit = 20
    }
    if (orientation && ['landscape', 'portrait'].indexOf(orientation) < 0) {
      orientation = ''
    }

    let result: any = {}
    const resultToReturn: ResultPhotoProps = {
      total: 0,
      limit,
      page,
      totalPage: 0,
      data: []
    }

    result = await pexelsAxios({
      url: query ? '/v1/search?' : '/v1/curated?',
      params: {
        query: query,
        orientation: orientation,
        page: page,
        per_page: limit
      },
      method: 'get'
    })
    if (Array.isArray(result?.data?.photos)) {
      resultToReturn.next_page = result.data.next_page
      resultToReturn.total = result.data.total_results
      resultToReturn.totalPage = Math.ceil(result.data.total / limit)
      resultToReturn.data = result.data.photos.map(
        ({
          src,
          width,
          height,
          url,
          photographer,
          photographer_url: photographerUrl
        }: any) => ({
          thumbnail: src.tiny,
          // image: src[orientation] || src.large,
          image: src.original,
          width,
          height,
          from,
          link: url || 'https://pexels.com',
          author: { name: photographer, link: photographerUrl }
        })
      )
    }
    return resultToReturn
  }
  return {
    search: searchPhotos,
    searchPhotos,
    searchVideos
  }
}

export default api
