import { createFFmpeg, fetchFile, FFmpeg } from '@ffmpeg/ffmpeg'

class FFmpegService {
  ffmpeg: FFmpeg = createFFmpeg({
    corePath:
      'https://vplate-s3.s3.ap-northeast-2.amazonaws.com/ffmpeg/ffmpeg-core.js',
    wasmPath:
      'https://vplate-s3.s3.ap-northeast-2.amazonaws.com/ffmpeg/ffmpeg-core.wasm',
    mainName: 'main',
    log: true
  })
  newFileName = `output.mp4`
  thumbnailName = `output.png`
  get isSupported() {
    return (window as any).SharedArrayBuffer !== undefined
  }
  async init(cb?: () => void) {
    if (!this.ffmpeg.isLoaded()) {
      await this.ffmpeg.load()
    }
    cb?.()
    // if ((window as any).SharedArrayBuffer !== undefined) {
    //   await this.ffmpeg.load()
    //   cb?.()
    // } else {
    //   console.log('ffmpeg load failed: SharedArrayBuffer')
    // }
  }

  async runFfmpegCommand(
    command: string[],
    fileName: string,
    blobType: 'video/mp4' | 'image/png'
  ) {
    this.ffmpeg.run(...command)
    const data = this.ffmpeg.FS('readFile', fileName)
    this.ffmpeg.FS('unlink', fileName)
    await this.ffmpeg.exit()
    const blob = new Blob([data.buffer], { type: blobType })
    return blob
  }

  async doTranscode(
    filename: string,
    file: File,
    startTime: number,
    duration: number,
    cropWidth: number,
    cropHeight: number,
    scaleWidth: number,
    scaleHeight: number,
    cropX: number,
    cropY: number
  ) {
    return new Promise<{ videoBlob: Blob; thumbnailBlob: Blob }>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        if (!this.ffmpeg.isLoaded()) {
          try {
            await this.ffmpeg.load()
          } catch (e) {
            reject(e)
            return
          }
        }
        setTimeout(async () => {
          try {
            this.ffmpeg.FS('writeFile', filename, await fetchFile(file))
            const command = [
              '-ss',
              `${startTime}`,
              '-accurate_seek',
              '-i',
              filename,
              '-t',
              `${duration}`,
              '-c:v',
              'libx264',
              '-an',
              '-b:v',
              '5M',
              '-minrate',
              '5M',
              '-maxrate',
              '5M',
              '-bufsize',
              '1G',
              '-rtbufsize',
              '1G',
              '-filter:v',
              `crop=w=${cropWidth}:h=${cropHeight}:x=${cropX}:y=${cropY}`,
              '-s',
              `${scaleWidth}x${scaleHeight}`,
              '-preset',
              'ultrafast',
              '-crf',
              '22',
              this.newFileName
            ]
            const videoBlob = await this.runFfmpegCommand(
              command,
              this.newFileName,
              'video/mp4'
            )
            // // ffmpeg를 통해 크롭된 결과물로 다시 ffmpeg 커맨드를 실행해 썸네일 생성
            await this.ffmpeg.load()
            this.ffmpeg.FS(
              'writeFile',
              this.newFileName,
              await fetchFile(videoBlob)
            )

            const thumbnailCommand = [
              '-i',
              this.newFileName,
              '-ss',
              '1',
              '-vframes',
              '1',
              '-y',
              this.thumbnailName
            ]
            const thumbnailBlob = await this.runFfmpegCommand(
              thumbnailCommand,
              this.thumbnailName,
              'image/png'
            )
            resolve({ videoBlob, thumbnailBlob })
          } catch (error) {
            reject(error)
          }
        }, 500)
      }
    )
  }
  async doConversion(filename: string, file: File) {
    return new Promise<{ videoUrl: string }>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        if (!this.ffmpeg.isLoaded()) {
          try {
            await this.ffmpeg.load()
          } catch (e) {
            reject(e)
            return
          }
        }
        setTimeout(async () => {
          try {
            this.ffmpeg.FS('writeFile', filename, await fetchFile(file))
            const command = [
              '-i',
              filename,
              '-c:v',
              'libx264',
              '-preset',
              'superfast',
              this.newFileName
            ]
            const videoBlob = await this.runFfmpegCommand(
              command,
              this.newFileName,
              'video/mp4'
            )
            const videoUrl = URL.createObjectURL(videoBlob)
            resolve({ videoUrl })
          } catch (error) {
            reject(error)
          }
        }, 500)
      }
    )
  }
}

export default new FFmpegService()
