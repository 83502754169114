import { loading } from '../../assets/lottie'
import Lottie, { Options } from 'react-lottie'

interface LoadingLottieProps {
  wrapperClass?: string
  options?: Options
  width?: number
  height?: number
}

const defaultOptions = {
  width: '100%',
  height: '100%',
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const LoadingLottie = ({
  wrapperClass,
  options,
  width = 80,
  height = 80
}: LoadingLottieProps): JSX.Element => {
  return (
    <div className={wrapperClass}>
      <Lottie
        options={{ ...defaultOptions, ...options }}
        width={width}
        height={height}
      />
    </div>
  )
}

export default LoadingLottie
