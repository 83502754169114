import styled, { css } from 'styled-components'
import { ButtonProps } from '.'

export const primaryCss = css`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
`

export const secondaryCss = css`
  color: ${({ theme }) => theme.colors.text.link};
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.text.link};
`

export const linkCss = css`
  color: ${({ theme }) => theme.colors.text.link};
  background-color: transparent;
  border-color: transparent;
`

export const disabledCss = css`
  background-color: ${({ theme }) => theme.colors.button.disabled};
  color: ${({ theme }) => theme.colors.text.disabled};
  border-color: ${({ theme }) => theme.colors.button.disabled};
  cursor: unset;
`

export const ButtonCompo = styled.button<ButtonProps>`
  width: ${({ block }) => (block ? '100%' : 'auto')};
  height: auto;
  position: relative;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '8px 16px'
      case 'large':
        return '16px'
      case 'default':
      default:
        return '12px 16px'
    }
  }};
  border-radius: ${({ round }) => (round ? '24px' : '4px')};
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '14px'
      case 'large':
        return '16px'
      case 'default':
      default:
        return '16px'
    }
  }};
  font-weight: 700;
  line-height: 1;
  background-color: transparent;
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  vertical-align: middle;
  text-align: center;
  transition: opacity 0.125s ease-in-out;
  user-select: none;
  white-space: nowrap;

  ${({ primary }) => primary && primaryCss};
  ${({ secondary }) => secondary && secondaryCss};
  ${({ link }) => link && linkCss};

  & > * {
    vertical-align: middle;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    ${disabledCss};
    transform: unset;
  }
`
