import { useEffect, useState } from 'react';

const useDetectKeyboardOpen = (minKeyboardHeight: number) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>();
    useEffect(() => {
        const listener = () => {
            if (window.visualViewport) {
                const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height
                if (isKeyboardOpen != newState) {
                    setIsKeyboardOpen(newState);
                }
            }
        };
        window?.visualViewport?.addEventListener('resize', listener);
        return () => {
            window?.visualViewport?.removeEventListener('resize', listener);
        };
    }, []);

    return isKeyboardOpen;
};

export default useDetectKeyboardOpen;