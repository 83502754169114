import { useMemo } from 'react'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import useBreakpoint from '../../hooks/useBreakpoint'
import Count from './Count'
import { UseModalProps } from '../../types/useModal'
import { useProjectContext } from '../../hooks/useProject'
import styled from 'styled-components'

const TopContent = ({ previewModal }: { previewModal: UseModalProps }) => {
  const { project } = useProjectContext()
  const { isBreakpoint } = useBreakpoint()

  const completedNumbers = useMemo(() => {
    const result = {
      video: 0,
      image: 0,
      text: 0
    }
    if (Array.isArray(project?.design.sources)) {
      // eslint-disable-next-line no-unused-expressions
      project?.design.sources.forEach((sources, i) => {
        if (Array.isArray(sources?.source)) {
          sources.source.forEach((source, j) => {
            if (project.sources[i][j]) {
              switch (source.sourceType) {
                case 'I':
                  result.image += 1
                  break
                case 'T':
                  result.text += 1
                  break
                case 'V':
                  result.video += 1
                  break
                default:
                  break
              }
            }
          })
        }
      })
    }
    return result
  }, [project])

  return (
    <Wrapper small={isBreakpoint('small')}>
      <FlexBetween small={isBreakpoint('small')}>
        <div>
          <Typography bold style={{ whiteSpace: 'nowrap' }}>
            템플릿 구성요소
          </Typography>
        </div>
        {!isBreakpoint('small') && (
          <div>
            <Button
              size={isBreakpoint('small') ? 'small' : 'default'}
              secondary
              style={{ marginRight: 6 }}
              onClick={() => previewModal.open()}
            >
              미리보기
            </Button>
          </div>
        )}
      </FlexBetween>
      <Count
        duration={project?.design?.videoDuration}
        text={{
          current: completedNumbers.text,
          required: project?.design?.needText
        }}
        video={{
          current: completedNumbers.video,
          required: project?.design?.needVideo
        }}
        photo={{
          current: completedNumbers.image,
          required: project?.design?.needImage
        }}
      />
    </Wrapper>
  )
}

export default TopContent

const Wrapper = styled.div<{ small: boolean }>`
  ${({ small }) =>
    small
      ? `
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 4px;
        `
      : `display: block;
  `}
`

const FlexBetween = styled.div<{ small: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ small }) => (small ? '20px 16px' : '12px 0 0 0')};
`
