import {
  IconDisabled,
  IconDisabledLarge,
  IconDisabledNormal
} from '../../assets/icons'

interface CloseIconProps {
  size?: string
}

const CloseIcon = ({ size }: CloseIconProps) => {
  switch (size) {
    case 'small':
      return <IconDisabled />
    case 'medium':
      return <IconDisabledNormal />
    default:
      return <IconDisabledLarge />
  }
}

export default CloseIcon
