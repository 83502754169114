import styled from 'styled-components'
import iconZoomIn from '../../assets/icons/icon_zoom_in.svg'
import iconZoomOut from '../../assets/icons/icon_zoom_out.svg'

interface ZoomButtonsProps {
  onClickZoomIn: () => void
  onClickZoomOut: () => void
}

const ZoomButtons = ({ onClickZoomIn, onClickZoomOut }: ZoomButtonsProps) => {
  return (
    <ButtonWrapper>
      <StyledButton icon={iconZoomIn} onClick={onClickZoomIn}></StyledButton>
      <StyledButton icon={iconZoomOut} onClick={onClickZoomOut}></StyledButton>
    </ButtonWrapper>
  )
}

export default ZoomButtons

const ButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 4px;
`

const StyledButton = styled.button`
  width: 30px;
  height: 30px;
  background: ${(props: { icon: string }) =>
    `url(${props.icon}) no-repeat center`};
  border: none;
  cursor: pointer;
`
