import { useTheme } from 'styled-components'
import Button from '../Button'
import Typography from '../Typography'
import { MUSIC_GENRES, MUSIC_MOODS } from './constants'
import { Filter, FilterWrapper } from './styles'
import { MusicFilterContentProps } from './types'

const MusicFilterContent = ({
  selectedGenres,
  setSelectedGenres,
  selectedMoods,
  setSelectedMoods,
  desktop
}: MusicFilterContentProps) => {
  const theme = useTheme()

  return (
    <div style={{ width: '100%' }}>
      {desktop && (
        <div
          style={{
            width: '100%',
            backgroundColor: theme.colors.border,
            padding: 16
          }}
        >
          <Typography type='body1' center bold block>
            필터
          </Typography>
        </div>
      )}
      <FilterWrapper>
        <div
          style={{
            borderRight: `1px solid ${theme.colors.border}`,
            flex: 1,
            padding: 16
          }}
        >
          <div style={{ padding: '0 16px' }}>
            <Typography type='body2' bold>
              분위기
            </Typography>
          </div>
          <Filter>
            {MUSIC_MOODS.map((item, index) => (
              <Button
                key={item}
                size='small'
                link={!selectedMoods.includes(index)}
                primary={selectedMoods.includes(index)}
                onClick={() => {
                  if (index === 0) {
                    setSelectedMoods([0])
                    return
                  }
                  setSelectedMoods((current) =>
                    current.includes(index)
                      ? current.filter((value) => value !== index)
                      : [...current, index]
                  )
                }}
              >
                <Typography type='body2'>{item}</Typography>
              </Button>
            ))}
          </Filter>
        </div>
        <div
          style={{
            flex: 1,
            padding: 16
          }}
        >
          <div style={{ padding: '0 16px' }}>
            <Typography type='body2' bold>
              장르
            </Typography>
          </div>
          <Filter>
            {MUSIC_GENRES.map((item, index) => (
              <Button
                key={item}
                size='small'
                link={!selectedGenres.includes(index)}
                primary={selectedGenres.includes(index)}
                onClick={() => {
                  if (index === 0) {
                    setSelectedGenres([0])
                    return
                  }
                  setSelectedGenres((current) =>
                    current.includes(index)
                      ? current.filter((value) => value !== index)
                      : [...current, index]
                  )
                }}
              >
                <Typography type='body2'>{item}</Typography>
              </Button>
            ))}
          </Filter>
        </div>
      </FilterWrapper>
    </div>
  )
}

export default MusicFilterContent
