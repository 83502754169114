import Typography from '../Typography'
import infoIcon from '../../assets/icons/icon_content_caution_outline_12px_gray.svg'
import useBreakpoint from '../../hooks/useBreakpoint'
import { Tooltip } from 'antd'
import { BgmTooltipProps } from './types'

const BgmTooltip = ({ title, label }: BgmTooltipProps) => {
  const { isBreakpoint } = useBreakpoint()
  return (
    <Tooltip
      title={title}
      placement='right'
      overlayInnerStyle={{ fontSize: isBreakpoint('small') ? 10 : 14 }}
    >
      <Typography bold type='body2'>
        {label}
      </Typography>
      <img
        src={infoIcon}
        alt='정보'
        style={{ verticalAlign: 'middle', marginLeft: 3 }}
      />
    </Tooltip>
  )
}

export default BgmTooltip
