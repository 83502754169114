import { useQuery } from 'react-query'
import { service } from '../../utils/api'

const fetchMusicList = async () => {
  const res = await service().music.list({ limit: 9999, page: 1 })
  if (Array.isArray(res?.result)) {
    return res.result
  }
  return []
}

export const useMusicQuery = () => {
  const { data: musicList, isLoading } = useQuery('musicList', fetchMusicList, {
    cacheTime: Infinity,
    staleTime: Infinity
  })
  return { musicList, isLoading }
}
