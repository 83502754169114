import { IconFilter } from '../../assets/icons'
import theme from '../../styles/theme'
import { Music } from '../../utils/api/files'
import { formatSeconds } from '../../utils/libs'
import Typography from '../Typography'
import { FilterButton, StyledButton } from './styles'

export const defaultBgmTooltip = (
  <>작업 중인 템플릿에 설정된 오리지널 배경음입니다.</>
)

export const changedBgmTooltip = (
  <>
    최종 완료될 광고영상에 적용될 배경음입니다. <br />
    기존 배경음으로 되돌리고 싶다면, <br /> 우측에 &apos;기존 배경음으로&apos;
    버튼을 눌러주세요.
  </>
)

export const MUSIC_MOODS = [
  '전체',
  '밝은',
  '화난',
  '진지한',
  '어두운',
  '행복한',
  '펑키한',
  '편안한',
  '이상한',
  '낭만적인',
  '몽환적인'
]

export const MUSIC_GENRES = [
  '전체',
  '팝',
  '힙합',
  '재즈 및 블루스',
  'R&B',
  '클래식',
  '댄스 및 일렉트로닉',
  '컨트리 및 포크',
  '아동',
  '펑크',
  '잔잔한 음악'
]

export const waveformSettings = {
  barWidth: 1,
  barGap: 0,
  waveformColor: theme.colors.text.primary,
  barAlign: 'center',
  waveformHeight: 48,
  waveformWidth: 1920,
  drawMode: 'png'
}

export const containerStyle = {
  zIndex: '500',
  backgroundColor: theme.colors.white,
  width: '70vw',
  maxHeight: '50vh',
  touchAction: 'pan-y pan-x',
  overflow: 'auto',
  maxWidth: '460px'
}

export const getMusicInfo = (selectedMusic?: Music) => {
  return (
    <StyledButton link block>
      <div>{selectedMusic?.musicTitle}</div>
      <div>
        <Typography type='caption1'>
          {formatSeconds(selectedMusic?.musicLength || 0)}
        </Typography>
      </div>
    </StyledButton>
  )
}

export const getFilterButton = (onClick: () => void) => (
  <div>
    <FilterButton link onClick={onClick}>
      <IconFilter height={14} />
      <Typography bold type='body2'>
        필터
      </Typography>
    </FilterButton>
  </div>
)
