import { Dispatch, SetStateAction } from 'react'
import { BgmType, Project } from '../../types/project'

import {
  iconBGMActive,
  iconBGM,
  iconMainColor,
  iconMainColorActive,
  iconSubColor,
  iconSubColorActive,
  iconFontColor,
  iconFontColorActive
} from '../../assets/icons'
import MusicPanel from '../../components/MusicPanel'
import ColorPanel from '../../components/ColorPanel'
import { SetProject } from '../../hooks/useProject'

import { message } from 'antd'
import { Music } from '../../utils/api/service'

type GetMenuParams = {
  project?: Project
  selectedDrawerIndex: number
  setSelectedDrawerIndex: Dispatch<SetStateAction<number>>
  setProject: SetProject | undefined
  isMutateLoading?: boolean
  setIsModified: Dispatch<SetStateAction<boolean>>
}

export const getMenus = ({
  project,
  selectedDrawerIndex,
  setSelectedDrawerIndex,
  setProject,
  isMutateLoading,
  setIsModified
}: GetMenuParams) => {
  const onClose = () => setSelectedDrawerIndex(-1)
  const changeColor = async (index: number, newColor: string | undefined) => {
    if (newColor && project && Array.isArray(project?.changedColor)) {
      project.changedColor.splice(index, 1, newColor)

      setProject &&
        (await setProject({
          ...project,
          changedColor: project?.changedColor
        }))
      message.success('변경되었습니다.')
      setIsModified(true)
    }
    onClose()
  }

  const onChangeBgm = async (
    newBgmSrc: string | null,
    newBgm: Music | null
  ) => {
    // TODO 추후에 제대로 개선해야 할 것
    const copiedCur = { ...project } as Project
    const copiedCurBgm = { ...project?.bgm } as BgmType

    setProject &&
      copiedCur &&
      (await setProject({
        ...project,
        ...copiedCur,
        bgmUrl: newBgmSrc,
        bgm: {
          ...copiedCurBgm,
          musicTitle: newBgm?.musicTitle || null,
          _id: newBgm?._id || null
        }
      }))
    setIsModified(true)
  }

  return [
    {
      icons: {
        default: iconMainColor,
        active: iconMainColorActive
      },
      title: '주조색상 변경하기',
      key: 'mainColor',
      onClick: () =>
        setSelectedDrawerIndex((current: number) => (current === 0 ? -1 : 0)),
      drawer: {
        visible: selectedDrawerIndex === 0,
        children: (
          <ColorPanel
            isMutateLoading={isMutateLoading}
            defaultColor={project?.design.temColor[0]}
            currentColor={
              project?.changedColor[0] || project?.design.temColor[0]
            }
            onColorChange={(newColor) => changeColor(0, newColor)}
          />
        ),
        onClose
      }
    },
    {
      icons: {
        default: iconSubColor,
        active: iconSubColorActive
      },
      title: '보조색상 변경하기',
      key: 'subColor',
      onClick: () =>
        setSelectedDrawerIndex((current) => (current === 1 ? -1 : 1)),
      drawer: {
        visible: selectedDrawerIndex === 1,
        children: (
          <ColorPanel
            isMutateLoading={isMutateLoading}
            defaultColor={project?.design.temColor[1]}
            currentColor={
              project?.changedColor[1] || project?.design.temColor[1]
            }
            onColorChange={(newColor) => changeColor(1, newColor)}
          />
        ),
        onClose
      }
    },
    {
      icons: {
        default: iconFontColor,
        active: iconFontColorActive
      },
      title: '폰트색상 변경하기',
      key: 'fontColor',
      onClick: () =>
        setSelectedDrawerIndex((current) => (current === 2 ? -1 : 2)),
      drawer: {
        visible: selectedDrawerIndex === 2,
        children: (
          <ColorPanel
            isMutateLoading={isMutateLoading}
            defaultColor={project?.design.temColor[2]}
            currentColor={
              project?.changedColor[2] || project?.design.temColor[2]
            }
            onColorChange={(newColor) => changeColor(2, newColor)}
          />
        ),
        onClose
      }
    },
    {
      icons: {
        default: iconBGM,
        active: iconBGMActive
      },
      title: '배경음 변경하기',
      key: 'bgm',
      onClick: () =>
        setSelectedDrawerIndex((current) => (current === 3 ? -1 : 3)),
      drawer: {
        visible: selectedDrawerIndex === 3,
        children: (
          <MusicPanel
            isMutateLoading={isMutateLoading}
            duration={project?.design?.videoDuration}
            changedBgmTitle={project?.bgm?.musicTitle}
            defaultMusicUrl={project?.design?.temPreviewVideo}
            currentMusicUrl={project?.bgmUrl}
            onChange={onChangeBgm}
            visible={selectedDrawerIndex === 3}
          />
        ),
        onClose
      }
    }
  ]
}
