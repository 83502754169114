import styled, { css } from 'styled-components'

import container from '../../components/Container'
import { Input } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'

export const Wrapper = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  ${({ theme }) =>
    theme.breakpoints.small(css`
      height: 50px;
    `)}
`

export const GoBackButtonWrapper = styled.div`
  width: 20%;
  max-width: 490px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`

export const Container = styled(container)`
  height: 100%;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      justify-content: center;
      text-align: center;

      & > div:first-child {
        margin: 0 auto;
      }
    `)}
  & > div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`

export const TitleWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 0;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      right: 2px;
    `)}
`

export const BtnEdit = styled(Button)`
  &:disabled {
    cursor: not-allowed;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 14px;
      padding: 10px !important;
    `)};

  ${({ theme }) =>
    theme.breakpoints.small(css`
      padding: 10px 8px !important;
      font-size: 12px;
    `)};
`

export const StyledInput = styled(Input)`
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgb(229 73 72 / 20%);
    box-shadow: 0 0 0 2px rgb(229 73 72 / 20%);
  }
`

export const StyledTypography = styled(Typography)`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 78%;
    `)};

  ${({ theme }) =>
    theme.breakpoints.small(css`
      width: 70%;
    `)};
`
