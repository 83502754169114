export const DRAWER_CLASSNAMES = {
  VISIBLE: 'drawer--visible',
  INVISIBLE: 'drawer--invisible',
  HAS_CHILD: 'drawer--has-child',
  TRANSITION: 'drawer-transition'
} as const

export const DRAWER_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom'
} as const
