import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Editor from './Editor'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient()
const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='App'>
        <Switch>
          <Route path='/:projectId'>
            <Editor />
          </Route>
        </Switch>
      </div>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
