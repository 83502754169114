import styled from 'styled-components'
import Typography from '../Typography'

export const ColorPickerWrapper = styled.div`
  width: 300px;
  height: auto;

  .react-colorful {
    width: 100%;
    height: 100%;

    // NOTE: 위 아래 박스 pointer 스타일링
    .react-colorful__pointer {
      position: absolute;
      z-index: 1;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    }

    .react-colorful__saturation {
      position: relative;
      flex-grow: 1;
      height: 140px;
      border-color: transparent;
      border-bottom: 12px solid ${({ theme }) => theme.colors.black};
      border-radius: 0 !important;
    }
    .react-colorful__last-control {
      margin-top: 8px !important;
      border-radius: 0 !important;
      height: 12px;
    }
  }
`

export const HexColorInputWrapper = styled.div`
  input {
    width: 100px;
    padding: 9px 16px;
    color: ${({ theme }) => theme.colors.text.link};
    border: solid 1px ${({ theme }) => theme.colors.text.disabled};
    border-radius: 2px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
  padding-bottom: 72px;
  overflow: auto;
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  gap: 8px;
`

export const Footer = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 16px 32px;
  flex-direction: row-reverse;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`

export const Preview = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  margin-bottom: 8px;
  & > div {
    width: auto;
    height: auto;
    position: relative;
    text-align: center;
    flex: 1;
  }
`

export const CanNotEdit = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SecondaryTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 8px;
`

export const ColorTitle = styled(Typography)`
  margin-bottom: 18px;
`
