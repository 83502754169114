import React, { useState } from 'react'
import Tooltip from '../../components/Tooltip'
import Drawer from '../../components/Drawer'
import useBreakpoint from '../../hooks/useBreakpoint'
import Typography from '../../components/Typography'
import { Background, Button, DrawerWrapper, Wrapper } from './_stylesSidebar'
import { getMenus } from './constants'
import { useProjectContext } from '../../hooks/useProject'

const Sidebar = ({
  setIsModified
}: {
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { project, setProject, isMutateLoading } = useProjectContext()
  const [selectedDrawerIndex, setSelectedDrawerIndex] = useState(-1)
  const menu = getMenus({
    project,
    selectedDrawerIndex,
    setSelectedDrawerIndex,
    setProject,
    isMutateLoading,
    setIsModified
  })

  const { isBreakpoint } = useBreakpoint()
  return (
    <Wrapper>
      <Background />
      {menu?.map(({ icons, title, key, drawer, onClick }) => (
        <React.Fragment key={key || JSON.stringify(icons)}>
          <Tooltip
            title={title}
            position={isBreakpoint('xLarge') ? 'top' : 'right'}
          >
            <Button type='button' icons={icons} onClick={onClick} />
          </Tooltip>
          {drawer && (
            <DrawerWrapper visible={!!drawer.visible}>
              <Drawer
                style={{ paddingBottom: isBreakpoint('xLarge') ? 51 : 0 }}
                title={title}
                visible={drawer.visible}
                onClose={drawer.onClose}
                onGoBack={drawer.onClose}
                backButton={
                  isBreakpoint('xLarge') && <Typography>&lt; 이전</Typography>
                }
                closable
                position={isBreakpoint('xLarge') ? 'bottom' : 'left'}
              >
                {drawer.children}
              </Drawer>
            </DrawerWrapper>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

export default Sidebar
