import Axios from 'axios'
import qs from 'querystring'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useInView } from 'react-intersection-observer'
import { InfiniteData } from 'react-query'
import { useLocation } from 'react-router'
import { IconBlank } from '../../assets/icons'
import AspectRatioSizer from '../../components/AspectRatioSizer'
import PhotoFilter from '../../components/Dropdown/PhotoFilter'
import InputElement from '../../components/Input'
import Tabs from '../../components/Tabs'
import {
  PhotoData,
  ResultPhotoProps,
  ResultVideoProps
} from '../../utils/api/images'
import {
  AutoContent,
  BlankWrapper,
  Content,
  ImageList,
  ImageListItem,
  InputWrapper,
  LottieWrapper,
  ObserveElement,
  SearchForm
} from './_stylesModal'

interface PhotoModalTabsProps {
  inputRef: React.RefObject<HTMLInputElement>
  handleSearch: (e: React.FormEvent<HTMLFormElement>) => void
  showDropbox: boolean
  setOrientation: Dispatch<SetStateAction<string>>
  imageList:
    | InfiniteData<ResultPhotoProps | ResultVideoProps | undefined>
    | undefined
  isFetching: boolean
  listLoad: () => React.ReactNode
  fetchNextPage: () => void
  selectedImageUrl: string
  setSelectedImageUrl: Dispatch<SetStateAction<string>>
  previewLoadStart: () => void
  previewLoading: boolean
}

const PhotoModalTabs = (props: PhotoModalTabsProps) => {
  const {
    inputRef,
    handleSearch,
    showDropbox,
    setOrientation,
    imageList,
    isFetching,
    listLoad,
    fetchNextPage,
    selectedImageUrl,
    setSelectedImageUrl,
    previewLoadStart,
    previewLoading
  } = props

  // 쇼핑몰에서 자동으로 사진을 불러오는 경우
  const location = useLocation()
  const auto = qs.parse(location.search.replace('?', '')).auto as string
  const [autoImages, setAutoImages] = useState<string[]>([])

  const [selectedTabIndex, setSelectedTabIndex] = useState(1)
  const hasResult = imageList?.pages[0] && imageList.pages[0]?.total > 0
  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    inView && fetchNextPage()
  }, [fetchNextPage, inView])

  const list =
    imageList &&
    imageList.pages.map((page) => {
      return page?.data.map((d) => {
        const image = d as PhotoData
        return (
          <ImageListItem
            key={image.id}
            src={image.thumbnail}
            selected={image?.image === selectedImageUrl}
            onClick={() => {
              const url = `${image?.image}?w=1280`
              if (selectedImageUrl === url) return
              setSelectedImageUrl(url)
              previewLoadStart()
            }}
            isPreviewLoading={previewLoading}
          >
            <AspectRatioSizer aspect={16 / 9} />
          </ImageListItem>
        )
      })
    })

  const getTabs = () => {
    const fileTab = {
      title: '파일',
      onClick: () => {
        inputRef.current?.click()
      }
    }

    const autoTab = {
      title: 'AI 수집',
      children: (
        <div
          style={{
            position: 'relative',
            flex: 1
          }}
        >
          <AutoContent>
            <div
              style={{
                width: '100%'
              }}
            >
              {autoImages.length > 0 ? (
                <ImageList
                  style={{
                    flex: 1,
                    overflowY: 'auto'
                  }}
                >
                  {autoImages.map((image) => (
                    <ImageListItem
                      key={image}
                      src={image}
                      selected={image === selectedImageUrl}
                      onClick={() => {
                        if (selectedImageUrl === image) return
                        setSelectedImageUrl(image)
                        previewLoadStart()
                      }}
                      isPreviewLoading={previewLoading}
                    >
                      <AspectRatioSizer aspect={16 / 9} />
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <BlankWrapper>
                  <IconBlank />
                  <span>이미지가 없습니다.</span>
                </BlankWrapper>
              )}
            </div>
          </AutoContent>
        </div>
      ),
      onClick: () => setSelectedTabIndex(1)
    }

    const freeTab = {
      title: '무료 사진',
      children: (
        <div
          style={{
            position: 'relative',
            flex: 1
          }}
        >
          <SearchForm
            innerHeight={document.documentElement.clientHeight}
            onSubmit={handleSearch}
          >
            <InputWrapper>
              <InputElement
                name='query'
                placeholder='검색하기 (영어로 입력해주세요.)'
                block
                type='search'
                inputMode='search'
                autoComplete='off'
                style={{
                  zIndex: 200
                }}
              />
              {showDropbox && <PhotoFilter setOrientaion={setOrientation} />}
            </InputWrapper>
          </SearchForm>
          <Content>
            <div
              style={{
                width: '100%'
              }}
            >
              {hasResult ? (
                <ImageList
                  style={{
                    flex: 1,
                    overflowY: 'auto'
                  }}
                >
                  {list}
                </ImageList>
              ) : (
                <>
                  {!isFetching && (
                    <BlankWrapper>
                      <IconBlank />
                      <span>검색 결과가 없습니다.</span>
                    </BlankWrapper>
                  )}
                </>
              )}
              {isFetching ? (
                <LottieWrapper>{listLoad()}</LottieWrapper>
              ) : (
                <>{hasResult && <ObserveElement ref={observeRef} />}</>
              )}
            </div>
          </Content>
        </div>
      ),
      onClick: () => setSelectedTabIndex(auto ? 2 : 1)
    }
    if (auto) {
      return [fileTab, autoTab, freeTab]
    }
    return [fileTab, freeTab]
  }

  const handleAuto = useCallback(async () => {
    const url = `${process.env.REACT_APP_AUTO_API_URL}/images/${auto}`
    const result = (await Axios.get(url)) as { data: string[] }
    setAutoImages(result.data)
  }, [auto])

  useEffect(() => {
    if (auto) {
      handleAuto()
    }
  }, [auto, handleAuto])

  return <Tabs tabs={getTabs()} selectedTabIndex={selectedTabIndex} />
}

export default PhotoModalTabs
