import React from 'react'
import styled from 'styled-components'

import Button from '../Button'
import CloseIcon from '../CloseIcon'
import Typography from '../Typography'

export type TitleProps = {
  title?: string | React.ReactNode
  backButton?: string | React.ReactNode
  closable?: boolean
  sizeName?: string
  onGoBack?(): void
  onClose?(): void
}

const TitleBar = ({
  backButton,
  onGoBack,
  title,
  closable = true,
  onClose,
  sizeName
}: TitleProps) => (
  <Wrapper>
    <div
      style={{
        left: 0
      }}
    >
      {backButton && (
        <Button link onClick={onGoBack}>
          {backButton}
        </Button>
      )}
    </div>
    <div style={{ width: '100%', zIndex: 0, textAlign: 'center' }}>
      {typeof title === 'string' ? (
        <Typography bold type='body1'>
          {title}
        </Typography>
      ) : (
        title
      )}
    </div>
    <div
      style={{
        right: 0
      }}
    >
      {closable && (
        <Button link onClick={onClose}>
          <CloseIcon size={sizeName} />
        </Button>
      )}
    </div>
  </Wrapper>
)

export default React.memo(TitleBar, (prevProps, nextProps) => {
  return (
    prevProps.sizeName === nextProps.sizeName &&
    prevProps.onClose === nextProps.onClose &&
    prevProps.closable === nextProps.closable
  )
})

const Wrapper = styled.div`
  width: 100%;
  height: 52px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border};
  user-select: none;
  & > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`
