import React, { Fragment, useEffect, useRef, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import Typography from '../../components/Typography'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Checkbox, Tooltip } from 'antd'
import styled, { css } from 'styled-components'
import { getInfoByLength } from './constants'
import { IconInfo } from '../../assets/icons'

type TextModalProps = ModalProps & {
  value?: string
  textLength?: number
  onComplete?(value: string): void
}

const TextModal = (props: TextModalProps) => {
  const { onClose, visible, value, onComplete, textLength = 8 } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [isCheck, setIsCheck] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [temp, setTemp] = useState('')
  const info = getInfoByLength(inputValue.length, textLength)

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    if (onComplete) {
      onComplete(inputRef?.current?.value || '')
    }
  }

  useEffect(() => {
    if (visible) {
      setInputValue(value || '')
      setTemp(value || '')
      setIsCheck(value === ' ')
      if (value === ' ') return
      setTimeout(() => {
        if (!inputRef.current) return
        inputRef.current.focus()
        inputRef.current.select()
      }, 0)
    }
  }, [value, visible])

  useEffect(() => {
    isCheck && setInputValue(' ')
    !isCheck && setInputValue(temp === ' ' ? '' : temp)
    !isCheck && inputRef.current?.focus()
  }, [isCheck, temp])

  return (
    <Modal
      noFullHeight
      title='텍스트'
      visible={visible}
      onClose={onClose}
      fitContent
    >
      <StyledForm onSubmit={handleSubmit}>
        <div style={{ padding: 16 }}>
          <Typography type='body2' center block>
            {info?.icon}
            <span style={{ marginLeft: 10 }}>{info?.text}</span>
          </Typography>
        </div>
        <InputWrapper
          check={isCheck}
          onClick={() => isCheck && setIsCheck(false)}
        >
          <WhiteText check={isCheck}>이 텍스트가 비워졌습니다.</WhiteText>
          <Input
            block
            inputSize='small'
            value={inputValue}
            ref={inputRef}
            placeholder='텍스트를 입력해주세요'
            data-testid='input-text'
            disabled={isCheck}
            onChange={(e) => {
              setInputValue(e.target.value)
              setTemp(e.target.value)
            }}
            onBlur={() => inputValue === ' ' && setIsCheck(true)}
          />
        </InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={isCheck}
            style={{ fontWeight: 'bold' }}
            onClick={() => setIsCheck((prev) => !prev)}
          >
            텍스트 비우기
          </Checkbox>
          <Tooltip
            title={
              <Fragment>
                <span>선택하시면 이 영역의 텍스트를 </span>
                <br />
                <span>비우고 영상을 만들 수 있습니다.</span>
              </Fragment>
            }
          >
            <CIconInfo />
          </Tooltip>
        </CheckboxWrapper>
        <div style={{ textAlign: 'right' }}>
          <Typography type='caption1'>
            {inputValue.length}/{textLength}
          </Typography>
        </div>
        <ButtonWrapper>
          <div>
            <Button link size='small' type='button' onClick={onClose}>
              취소
            </Button>
          </div>
          <div>
            <Button primary size='small' type='submit'>
              저장하기
            </Button>
          </div>
        </ButtonWrapper>
      </StyledForm>
    </Modal>
  )
}

export default TextModal

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100vw;
  max-width: 356px;
  margin: 0 auto;
`

const InputWrapper = styled.div<{ check: boolean }>`
  background-color: transparent;
  ${({ theme, check }) =>
    check &&
    `
      position: relative;
      background-color: ${theme.colors.input.disabled};
      
      input {
        background-color: ${theme.colors.input.disabled};
      }
    `};
`

const WhiteText = styled.div<{ check: boolean }>`
  position: absolute;
  top: 12px;
  left: 15px;
  color: ${({ theme }) => theme.colors.text.secondary};
  opacity: ${({ check }) => (check ? 0.9 : 0)};
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox-wrapper {
    font-size: 14px;

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        font-size: 12px;
      `)}
  }

  .ant-checkbox + span {
    padding-right: 0px;
  }
`

const CIconInfo = styled(IconInfo)`
  width: 15px;
  height: 15px;
  margin-top: 4px;
  margin-left: 8px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-top: 6px;
    `)}
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`
