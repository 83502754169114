import Modal from '../../components/Modal'
import styled, { css } from 'styled-components'
import CloseIcon from '../../components/CloseIcon'
import Button from '../../components/Button'
import useBreakpoint from '../../hooks/useBreakpoint'

type Image = {
  url: string
  alt: string
}

interface NotificationModalProps {
  onClose: () => void
  img: Image
  contents: string[]
  buttonContent: string
  onClick: () => void
  color?: string
}

const NotificationModal = ({
  onClose,
  img,
  contents,
  buttonContent,
  onClick,
  color
}: NotificationModalProps) => {
  const { isBreakpoint } = useBreakpoint()
  return (
    <Modal
      visible
      onClose={onClose}
      notitleBar
      noFullHeight
      fitContent
      style={style}
      wrapperStyle={wrapperStyle}
      blur
    >
      <CloseButtonWrapper className='close-button-wrapper'>
        <Button link onClick={onClose}>
          <CloseIcon />
        </Button>
      </CloseButtonWrapper>
      <Wrapper className='content-wrapper'>
        <img src={img.url} alt={img.alt} />
        <TextWrapper className='text-wrapper' color={color}>
          {contents.map((text) => (
            <span
              key={text}
              style={{
                fontSize: 16,
                fontWeight: 'bold'
              }}
            >
              {text}
            </span>
          ))}
        </TextWrapper>
        <ButtonWrapper className='primary-button-wrapper'>
          <Button
            primary
            size={isBreakpoint('small') ? 'small' : 'default'}
            onClick={onClick}
          >
            {buttonContent}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  )
}

export default NotificationModal

const wrapperStyle = css`
  width: fit-content !important;
  position: relative;
  border: none;
  border-radius: 30px;
  overflow: hidden;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      height: fit-content;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    `)};

  ${({ theme }) =>
    theme.breakpoints.small(css`
      width: 95% !important;
      top: unset !important;
      bottom: 10px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    `)};
`

const style = css`
  padding: 0px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    min-width: 300px;
  }
`

const responsivePadding = css`
  padding: 24px 0px;

  /* ${({ theme }) =>
    theme.breakpoints.small(css`
      padding: 12px 0px;
    `)} */
`

const TextWrapper = styled(Wrapper)<{ color?: string }>`
  width: 100%;
  gap: 6px;
  border-bottom: 1px solid ${({ theme, color }) => color || theme.colors.border};

  ${responsivePadding};
`

const ButtonWrapper = styled.div`
  width: fit-content;
  ${responsivePadding};
`
