import { Dispatch, SetStateAction } from 'react'
import Tabs from '../Tabs'
import ColorChips from './ColorChips'
import { basicColors } from './constants'

interface FavColorTabsProps {
  setColor?: Dispatch<SetStateAction<string>>
  favColor: string[]
}

const FavColorTabs = ({ setColor, favColor }: FavColorTabsProps) => {
  const tabList = [
    {
      title: '기본 색상',
      children: <ColorChips setColor={setColor} colors={basicColors} />
    },
    {
      title: '즐겨찾는 색상',
      children: <ColorChips setColor={setColor} colors={favColor} />
    }
  ]

  return (
    <div>
      <Tabs tabs={tabList} />
    </div>
  )
}

export default FavColorTabs
