import { isMobile } from 'react-device-detect'
import { useTheme } from 'styled-components'
import { formatSeconds } from '../../utils/libs'
import Typography from '../Typography'
import { BreakAllContainer, Row, StyledButton, StyledCol } from './styles'
import { MusicListProps } from './types'
import { useMusicQuery } from './_queries'

import LoadingLottie from '../Lottie'
import { Music } from '../../utils/api/service'
import MusicFilterContent from './MusicFilterContent'
import { useModal } from '../Modal'
import { Popover } from 'react-tiny-popover'
import { containerStyle, getFilterButton } from './constants'

const MusicList = ({
  selectedMoods,
  selectedGenres,
  setSelectedMusic,
  setSelectedMoods,
  setSelectedGenres,
  sizeName,
  setMobileFilterOpen
}: MusicListProps) => {
  const theme = useTheme()
  const filterPopover = useModal()
  const { musicList, isLoading } = useMusicQuery()

  const isStyleSize = sizeName === 'xxLarge' || sizeName === 'xxxLarge'

  return (
    <>
      <Row>
        <div>
          <Typography bold type='body2'>
            목록
          </Typography>
        </div>
        <div>
          {sizeName !== 'small' ? (
            <Popover
              content={() => (
                <MusicFilterContent
                  desktop
                  setSelectedMoods={setSelectedMoods}
                  selectedMoods={selectedMoods}
                  selectedGenres={selectedGenres}
                  setSelectedGenres={setSelectedGenres}
                />
              )}
              positions={isStyleSize ? ['right'] : ['left']}
              padding={isStyleSize ? 34 : 0}
              reposition
              isOpen={filterPopover.visible}
              align={'center'}
              onClickOutside={() => filterPopover.close()}
              containerStyle={{ ...containerStyle }}
            >
              {getFilterButton(() => filterPopover.toggle())}
            </Popover>
          ) : (
            getFilterButton(() => setMobileFilterOpen(true))
          )}
        </div>
      </Row>
      <StyledCol isMobile={isMobile}>
        <BreakAllContainer>
          {isLoading ? (
            <LoadingLottie />
          ) : (
            Array.isArray(musicList) &&
            musicList
              .filter((music: { musicMood: number; musicGenre: number }) => {
                return (
                  (selectedMoods.includes(0) ||
                    selectedMoods.includes(music.musicMood)) &&
                  (selectedGenres.includes(0) ||
                    selectedGenres.includes(music.musicGenre))
                )
              })
              .map((item: Music) => (
                <div
                  key={item._id}
                  style={{
                    borderBottom: `1px solid ${theme.colors.border}`
                  }}
                >
                  <StyledButton
                    link
                    block
                    onClick={() => setSelectedMusic(item)}
                  >
                    <div>♫ {item.musicTitle}</div>
                    <div>
                      <Typography type='caption1'>
                        {formatSeconds(item.musicLength)}
                      </Typography>
                    </div>
                  </StyledButton>
                </div>
              ))
          )}
        </BreakAllContainer>
      </StyledCol>
    </>
  )
}

export default MusicList
