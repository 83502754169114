import Audio from '../Audio'
import Button from '../Button'
import Drawer from '../Drawer'
import Typography from '../Typography'
import { getMusicInfo } from './constants'
import { Row } from './styles'
import { PreviewDrawerProps } from './types'

const PreviewDrawer = ({
  visible,
  onClose,
  selectedMusic,
  setSelectedMusic,
  setDrawerPage,
  handlePlay,
  handlePause
}: PreviewDrawerProps) => {
  return (
    <Drawer
      style={{ height: 'unset' }}
      position='bottom'
      visible={visible}
      title='배경음 듣기'
      closable
      onClose={onClose}
    >
      <Row>{getMusicInfo(selectedMusic)}</Row>
      <Row>
        <Typography block type='body2'>
          {selectedMusic?.musicDescription}
        </Typography>
      </Row>
      <Row>
        {visible && (
          <div style={{ width: '100%' }}>
            <Audio
              src={selectedMusic?.musicUrl}
              handlePlay={handlePlay}
              handlePause={handlePause}
            />
          </div>
        )}
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button
          size='small'
          secondary
          onClick={() => setSelectedMusic(undefined)}
        >
          닫기
        </Button>
        <Button size='small' primary onClick={() => setDrawerPage('trim')}>
          구간 설정
        </Button>
      </Row>
    </Drawer>
  )
}

export default PreviewDrawer
