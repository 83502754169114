import { useTheme } from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import Typography from '../Typography'
import Button from '../Button'
import Drawer from '../Drawer'
import { IconDisabled } from '../../assets/icons'
import { Music } from '../../utils/api/service'
import { message } from 'antd'
import { changedBgmTooltip, defaultBgmTooltip } from './constants'
import useBreakpoint from '../../hooks/useBreakpoint'
import MusicFilterContent from './MusicFilterContent'
import Audio from '../Audio'
import {
  Col,
  Container,
  FlexBetween,
  LottieContainer,
  Row,
  Wrapper
} from './styles'
import BgmTooltip from './BgmTooltip'
import LoadingLottie from '../Lottie'
import PreviewDrawer from './PreviewDrawer'
import TrimDrawer from './TrimDrawer'
import MusicList from './MusicList'
import { MusicPanelProps } from './types'

const MusicPanel = (props: MusicPanelProps) => {
  const theme = useTheme()
  const {
    isMutateLoading,
    changedBgmTitle,
    duration,
    defaultMusicUrl: initialDefaultMusicUrl,
    currentMusicUrl: initialCurrentMusicUrl,
    onChange = () => {},
    visible
  } = props

  const [defaultMusicUrl, setDefaultMusicUrl] = useState(initialDefaultMusicUrl)
  const [currentMusicUrl, setCurrentMusicUrl] = useState(initialCurrentMusicUrl)
  const [selectedMusic, setSelectedMusic] = useState<Music>()
  const [drawerPage, setDrawerPage] = useState<'preview' | 'trim'>('preview')
  const [startTime, setStartTime] = useState(0)
  const [playingTarget, setPlayingTarget] = useState<HTMLAudioElement | null>(
    null
  )
  // 장르 필터 오픈
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)

  const [selectedMoods, setSelectedMoods] = useState<number[]>([0])
  const [selectedGenres, setSelectedGenres] = useState<number[]>([0])
  const { sizeName } = useBreakpoint()
  const defaultMusicRef = useRef<HTMLAudioElement>(null)
  const currentMusicRef = useRef<HTMLAudioElement>(null)

  const handleClearClick = useCallback(() => {
    if (currentMusicUrl) {
      message.success('변경되었습니다.')
    }
    onChange(null, null)
  }, [onChange, currentMusicUrl])

  const handlePlay = useCallback(
    (audio: HTMLAudioElement) => {
      if (playingTarget !== audio) {
        playingTarget && playingTarget.pause()
        setPlayingTarget(audio)
      }
      audio.play()
    },
    [playingTarget]
  )

  const handlePause = useCallback(
    (audio: HTMLAudioElement) => {
      if (playingTarget === audio) {
        audio.pause()
        setPlayingTarget(null)
      }
    },
    [playingTarget]
  )

  const onCloseDrawer = useCallback(() => {
    setSelectedMusic(undefined)
    setStartTime(0)
  }, [])

  const hanleChangeMusic = useCallback(
    (musicUrl: string | null) => {
      setCurrentMusicUrl(musicUrl)
      selectedMusic && onChange(musicUrl, selectedMusic)
      setSelectedMusic(undefined)
      message.success('변경되었습니다.')
    },
    [onChange, selectedMusic]
  )

  useEffect(() => {
    if (!selectedMoods.length) {
      setSelectedMoods([0])
    }
    if (selectedMoods.length > 1 && selectedMoods.includes(0)) {
      setSelectedMoods((current) => current.filter((item) => item !== 0))
    }
  }, [selectedMoods])

  useEffect(() => {
    if (!selectedGenres.length) {
      setSelectedGenres([0])
    }
    if (selectedGenres.length > 1 && selectedGenres.includes(0)) {
      setSelectedGenres((current) => current.filter((item) => item !== 0))
    }
  }, [selectedGenres])

  useEffect(() => {
    if (!selectedMusic) {
      setDrawerPage('preview')
    }
  }, [selectedMusic])

  useEffect(() => {
    setDefaultMusicUrl(initialDefaultMusicUrl)
  }, [initialDefaultMusicUrl])

  useEffect(() => {
    setCurrentMusicUrl(initialCurrentMusicUrl)
  }, [initialCurrentMusicUrl])

  useEffect(() => {
    if (!visible) {
      defaultMusicRef.current?.pause && defaultMusicRef.current.pause()
      currentMusicRef.current?.pause && currentMusicRef.current.pause()
    }
  }, [visible])

  return (
    <Wrapper>
      <Container isMobile={isMobile}>
        <Row>
          <BgmTooltip title={defaultBgmTooltip} label='기존 배경음' />
        </Row>
        <Row
          style={{
            borderBottom: `1px solid ${theme.colors.border}`,
            padding: '16px 32px 32px'
          }}
        >
          <div style={{ width: '100%' }}>
            <Audio
              src={defaultMusicUrl && defaultMusicUrl}
              handlePlay={handlePlay}
              handlePause={handlePause}
            />
          </div>
        </Row>
        {isMutateLoading ? (
          <LottieContainer>
            <LoadingLottie width={50} height={50} />
          </LottieContainer>
        ) : (
          <>
            <Col>
              <div style={{ width: '100%' }}>
                <BgmTooltip title={changedBgmTooltip} label='변경 배경음' />
              </div>
              <FlexBetween>
                <Typography bold type='body1'>
                  {changedBgmTitle}
                </Typography>
                <Button size='small' link onClick={handleClearClick}>
                  <Typography bold type='body2'>
                    기존 배경음으로
                  </Typography>
                </Button>
              </FlexBetween>
            </Col>
            <Row>
              <div style={{ width: '100%' }}>
                {currentMusicUrl ? (
                  <Audio
                    src={currentMusicUrl}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                  />
                ) : (
                  <Typography block type='body2' center>
                    변경한 배경음이 없습니다.
                  </Typography>
                )}
              </div>
            </Row>
          </>
        )}
        <MusicList
          selectedMoods={selectedMoods}
          selectedGenres={selectedGenres}
          setSelectedMusic={setSelectedMusic}
          sizeName={sizeName}
          setSelectedMoods={setSelectedMoods}
          setSelectedGenres={setSelectedGenres}
          setMobileFilterOpen={setMobileFilterOpen}
        />
      </Container>
      <PreviewDrawer
        visible={selectedMusic && drawerPage === 'preview'}
        onClose={onCloseDrawer}
        selectedMusic={selectedMusic}
        setSelectedMusic={setSelectedMusic}
        setDrawerPage={setDrawerPage}
        handlePlay={handlePlay}
        handlePause={handlePause}
      />
      <TrimDrawer
        visible={selectedMusic && drawerPage === 'trim'}
        onClose={onCloseDrawer}
        selectedMusic={selectedMusic}
        setStartTime={setStartTime}
        duration={duration}
        startTime={startTime}
        handlePlay={handlePlay}
        handlePause={handlePause}
        hanleChangeMusic={hanleChangeMusic}
      />
      {sizeName === 'small' && (
        <Drawer
          style={{ height: 'unset' }}
          position='bottom'
          visible={mobileFilterOpen}
          title='필터'
          closable
          backButton={<IconDisabled />}
          onGoBack={() => setMobileFilterOpen(false)}
        >
          <MusicFilterContent
            setSelectedMoods={setSelectedMoods}
            selectedMoods={selectedMoods}
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
          />
        </Drawer>
      )}
    </Wrapper>
  )
}

export default MusicPanel
