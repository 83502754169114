export const MAKING = 0
export const START_RENDERING = 1
export const RENDERING = 2
export const COMPLETE = 3
export const CONFIRM = 4

export const getStatusText = (
  status: number,
  renderProgress: number | undefined,
  needCheck: boolean | undefined,
  renderCnt: number | undefined,
  maxCount: number
) => {
  if (status > MAKING && status < COMPLETE && renderProgress) {
    return `렌더링 중(${renderProgress}%)`
  }
  if (status === MAKING) {
    const text = needCheck ? ` (${renderCnt || 0}/${maxCount}회)` : ''
    return `완료하기${text}`
  }
  if (status >= COMPLETE) {
    return '수정하기'
  }
  return ''
}

export const warningMessage = `-  '  &  _ () 를 제외한 특수문자는 입력하실 수 없습니다.`

export const getTooltipMessage = (
  status: number,
  isModified: boolean,
  isLimit: boolean
) => {
  if (isLimit) {
    return '무료플랜에서는 5번의 영상 인코딩을 시도할 수 있습니다.\n베이직 플랜을 이용하시면 무제한 제작이 가능합니다.'
  }
  if (status >= COMPLETE && !isModified) {
    return '내용을 수정하면 새로운 영상을\n만들 수 있습니다.'
  }
  if (status < COMPLETE || (status >= COMPLETE && isModified)) {
    return '프로젝트 편집이 모두 끝났다면\n완료하기 버튼을 눌러주세요!'
  }
  return ''
}
