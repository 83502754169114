import welcome from '../../assets/images/welcome.png'
import NotificationModal from './NotificationModal'

interface FirstModalProps {
  onClose: () => void
}

const contents = [
  '무료 플랜으로 브이플레이트를 체험해보세요.',
  '월 5회까지 무료로 영상을 제작할 수 있어요.'
]

const FirstModal = ({ onClose }: FirstModalProps) => {
  return (
    <NotificationModal
      onClick={onClose}
      onClose={onClose}
      buttonContent='계속 제작하기'
      contents={contents}
      img={{ url: welcome, alt: 'welcome' }}
    />
  )
}

export default FirstModal
