import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 4px;
  padding: 4px;
  z-index: 400;

  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      width: auto;
      height: calc(100vh - 59px);
      left: 0;
      top: 60px;
      bottom: unset;
      flex-direction: column;
      border-right: 1px solid ${theme.colors.border};
    `)};
  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      position: fixed;
      width: 100%;
      height: auto;
      left: 0;
      top: unset;
      bottom: 0;
      flex-direction: row;
      border-top: 1px solid ${theme.colors.border};
      justify-content: center;
    `)};

  ${({ theme }) =>
    theme.breakpoints.small(css`
      justify-content: space-around;
    `)};
`

export const DrawerWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  z-index: 200 !important;
  transition: ${({ visible }) => (visible ? 'unset' : 'all 300ms step-end')};

  ${({ theme, visible }) =>
    theme.breakpoints.xxxLarge(css`
      width: 364px;
      height: 100%;
      top: 0;
      left: ${visible ? '49px' : '-364px'};
    `)}
  ${({ theme, visible }) =>
    theme.breakpoints.xLarge(css`
      width: 100vw;
      /* height: calc(100vh - 50px); */
      height: calc(var(--vh, 1vh) * 100 - 200px);
      top: unset;
      left: 0;
      bottom: ${visible ? '0' : '-100vh'};
    `)}

    ${({ theme, visible }) =>
    theme.breakpoints.small(css`
      position: fixed;
      width: 100vw;
      @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
        // height: calc(var(--vh, 1vh) * 100 - 200px) !important;
      }
      height: 100vh;
      left: 0;
      /* top: ${visible ? '0' : '0%'}; */
      bottom: ${visible ? '0' : '-100vh'};
    `)};
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 240;
`

type Icons = {
  default: string
  hover?: string
  active?: string
  disabled?: string
}
export const Button = styled.button<{ icons: Icons }>`
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('${({ icons }) => icons.default}');
  z-index: 240;

  &:hover {
    background-image: url('${({ icons }) => icons.hover || icons.default}');
    opacity: 0.8;
  }

  &:active {
    background-image: url('${({ icons }) => icons.active || icons.default}');
  }
`
