import { useInfiniteQuery } from 'react-query'
import { images } from '../utils/api'
import { ResultPhotoProps, ResultVideoProps } from '../utils/api/images'

// TYPE ///////////////////////

export const MEDIA_TYPE = {
  VIDEO: 0,
  PHOTO: 1
} as const
type MEDIA_TYPE = (typeof MEDIA_TYPE)[keyof typeof MEDIA_TYPE]

interface useFetchPexelsProps {
  type: MEDIA_TYPE
  words?: string
  minDuration?: number
  orientation?: string
  preventFetch?: boolean
}

// TYPE FIN ///////////////////////

const fetchImages = async (
  pageParam: number,
  words?: string,
  orientation?: string
): Promise<ResultPhotoProps | undefined> => {
  const res = await images().searchPhotos({
    query: words || '',
    page: pageParam,
    limit: 80,
    orientation: orientation,
    from: 'pexels'
  })
  if (res) {
    return res
  }
  return undefined
}

const fetchVideos = async (
  pageParam: number,
  minDuration: number,
  words?: string,
  from?: string
): Promise<ResultVideoProps | undefined> => {
  const res = await images().searchVideos({
    query: words || '',
    page: pageParam,
    limit: 80,
    from: from || 'pexels',
    minDuration: minDuration
  })
  if (res) {
    return res
  }
  return undefined
}

const useFetchPexels = ({
  type,
  words = '',
  minDuration,
  orientation,
  preventFetch
}: useFetchPexelsProps) => {
  const {
    data: media,
    fetchNextPage,
    refetch: refetchMedia,
    isFetching
  } = useInfiniteQuery(
    ['media', words, orientation, type],
    async ({ pageParam = 1 }) => {
      if (type === MEDIA_TYPE.PHOTO) {
        return await fetchImages(pageParam, words, orientation)
      } else {
        try {
          return await fetchVideos(pageParam, minDuration as number, words)
        } catch (error) {
          return await fetchVideos(
            pageParam,
            minDuration as number,
            words,
            'pixabay'
          )
        }
      }
    },
    {
      staleTime: 240000,
      cacheTime: Infinity,
      enabled: !preventFetch,
      getNextPageParam: (currentPage, allPage) => {
        if (currentPage?.data && currentPage?.data?.length <= 0) {
          return false
        }
        return allPage.length + 1
      }
    }
  )

  return { media, fetchNextPage, refetchMedia, isFetching }
}

export default useFetchPexels
