import Typography from '../Typography'
import styled, { css } from 'styled-components'

type Position = 'top' | 'right' | 'bottom' | 'left'

const Tooltip = ({
  title,
  children,
  position = 'top',
  touch = false
}: {
  title?: string | JSX.Element
  position?: Position
  children: JSX.Element
  touch?: boolean
}) => {
  return (
    <Wrapper>
      {children}
      {title && (
        <Message position={position} touch={touch}>
          <Typography type='caption1' style={{ display: 'block' }}>
            {typeof title === 'string'
              ? // eslint-disable-next-line react/no-array-index-key
                title.split('\n').map((s, i) => <p key={`${i}`}>{s}</p>)
              : title}
          </Typography>
        </Message>
      )}
    </Wrapper>
  )
}

export default Tooltip

const Message = styled.div<{ position?: Position; touch: boolean }>`
  ${({ theme, touch }) =>
    !touch &&
    theme.breakpoints.small(css`
      @supports (-webkit-touch-callout: none) {
        display: none;
      }
    `)}

  position: absolute;
  z-index: 9999 !important;
  white-space: nowrap;
  pointer-events: none;
  padding: 14px 10px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.text.secondary};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${({ position }) => {
    switch (position) {
      case 'right':
        return css`
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          margin-left: 4px;
        `
      case 'bottom':
        return css`
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 4px;
        `
      case 'left':
        return css`
          top: 50%;
          right: 100%;
          transform: translateY(-50%);
          margin-right: 4px;
        `
      case 'top':
      default:
        return css`
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: 4px;
        `
    }
  }}
`

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  & > ${Message} {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover > ${Message} {
    opacity: 1;
  }
`
