import NotificationModal from './NotificationModal'
import useBasic from '../../assets/images/use_basic.png'
import theme from '../../styles/theme'
import { Project } from '../../types/project'

interface BasicModalProps {
  onClose: () => void
  sendMessageToParent: (obj: { code: string; data?: string | Project }) => void
}

const BasicModal = ({ onClose, sendMessageToParent }: BasicModalProps) => {
  const onClick = () => {
    sendMessageToParent({
      code: 'ROUTE',
      data: '/pricing'
    })
  }
  return (
    <NotificationModal
      onClick={onClick}
      onClose={onClose}
      img={{
        url: useBasic,
        alt: 'free render end'
      }}
      contents={['베이직 플랜 결제하고', '광고영상을 무제한으로 제작하세요.']}
      buttonContent='요금제 보러가기'
      color={theme.colors.lightPink}
    />
  )
}

export default BasicModal
