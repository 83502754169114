import React, { useEffect, useState } from 'react'
import qs from 'querystring'
import { useLocation } from 'react-router-dom'

import useLocalStorage from '../../hooks/useLocalStorage'
import { Platform } from '../../utils/libs/platform'
import { datadogRum } from '@datadog/browser-rum'

export type TokenType = 'accessToken' | 'userId'
export type Auth = {
  token?: string
  type: TokenType
  platform: Platform
  isFirst: boolean | string
}

type QueryStringType = Auth & { user?: string }

export const AuthInitialValue: Auth = {
  token: '',
  type: 'accessToken',
  platform: Platform.VplateDev,
  isFirst: false
}

type UserType = {
  _id: string
  userName: string
  userPhoneNumber: string
  userEmail: string
}

const AuthHelper: React.FunctionComponent = ({ children }) => {
  const location = useLocation()
  const [auth, setAuth] = useLocalStorage<Auth>(
    'vplateVideoEditorAuth',
    AuthInitialValue
  )
  const [next, setNext] = useState(false)

  const setUserDatadog = (user: string) => {
    if (process.env.NODE_ENV !== 'production') return

    try {
      const userData = JSON.parse(user) as UserType
      const { _id, userName, userPhoneNumber, userEmail } = userData || {}
      datadogRum.setUser({
        id: _id,
        name: userName,
        userPhoneNumber,
        email: userEmail
      })
    } catch (error) {
      console.log('error', error)
      return
    }
  }

  useEffect(() => {
    const queryString = qs.parse(
      location.search.replace('?', '')
    ) as unknown as QueryStringType
    queryString?.user && setUserDatadog(queryString.user)
    const token = queryString?.token || AuthInitialValue.token
    const type: TokenType = queryString?.type || AuthInitialValue.type
    const platform = queryString?.platform || AuthInitialValue.platform
    const isFirst = queryString?.isFirst === 'true' || AuthInitialValue.isFirst

    setAuth({ token, type, platform, isFirst })
    setNext(true)
  }, [location])

  return <>{next && auth?.token && children}</>
}

export default AuthHelper
