import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { InputSize, INPUT_SIZES, TextAlign, TEXT_ALIGNS } from './constants'

type InputProps = React.ComponentPropsWithoutRef<'input'> & {
  inputSize?: InputSize
  block?: boolean
  prefixElement?: JSX.Element | string
  suffixElement?: JSX.Element | string
  textAlign?: TextAlign
}

const InputElement = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    prefixElement: prefix,
    suffixElement: suffix,
    // eslint-disable-next-line no-unused-vars
    ...rest
  } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper {...props}>
      {prefix}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Input {...rest} ref={ref} />
      <div style={{ marginRight: 8 }}>{suffix}</div>
    </Wrapper>
  )
})

export default InputElement

const Wrapper = styled.div<InputProps>`
  display: inline-flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.text.disabled};
  border-radius: 2px;
  ${({ block }) =>
    block &&
    css`
      display: flex;
    `}
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: ${({ inputSize }) => {
    switch (inputSize) {
      case INPUT_SIZES.SMALL:
        return '4px 8px'
      case INPUT_SIZES.LARGE:
        return '8px'
      case INPUT_SIZES.DEFAULT:
      default:
        return '6px 8px'
    }
  }};
`

const Input = styled.input<InputProps>`
  flex: 1;
  padding: ${({ inputSize }) => {
    switch (inputSize) {
      case INPUT_SIZES.SMALL:
        return '4px 8px'
      case INPUT_SIZES.LARGE:
        return '8px'
      case INPUT_SIZES.DEFAULT:
      default:
        return '6px 8px'
    }
  }};
  text-align: ${({ textAlign }) =>
    textAlign ? TEXT_ALIGNS[textAlign] : TEXT_ALIGNS.LEFT};
  border: none;
  border-radius: 0;
  width: 100%;
  ${({ block }) =>
    block &&
    css`
      display: block;
    `}
`
