import { Button } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import styled, { css } from 'styled-components'

import { ReactComponent as IconAlert } from '../../assets/icons/icon_alert_denger_circle_outline_normal_redff4545.svg'
import LoadingIndicator from '../../components/LoadingIndicator'

interface FileLoadingProps {
  isError: boolean
  onClose: () => void
}

const FileLoading = ({ isError, onClose }: FileLoadingProps) => {
  const screens = useBreakpoint()
  return (
    <>
      {!isError ? (
        <Container>
          <span>파일을 불러오는 중입니다.</span>
          <br />
          {screens.xs ? (
            <div style={{ textAlign: 'center' }}>
              <span>용량이 큰 파일은 불러오는 시간이</span>
              <br />
              <span>최대 5분 걸릴 수 있습니다.</span>
            </div>
          ) : (
            <span>
              용량이 큰 파일은 불러오는 시간이 최대 5분 걸릴 수 있습니다.
            </span>
          )}
          <LoadingIndicator style={{ marginTop: 10 }} />
        </Container>
      ) : (
        <ErrorContainer>
          <BoldWrapper>
            <CIconAlert />
            파일 불러오기
          </BoldWrapper>
          <div style={{ marginTop: 10 }}>
            파일 불러오는 중에 오류가 발생했습니다.
          </div>
          <div>다시 한 번 시도해주세요.</div>
          <div style={{ marginTop: 10 }}>오류가 반복될 경우</div>
          <div>비정상적인 파일인지 확인 바랍니다.</div>
          <div style={{ textAlign: 'right', marginTop: 10 }} onClick={onClose}>
            <StyledButton>확인</StyledButton>
          </div>
        </ErrorContainer>
      )}
    </>
  )
}

export default FileLoading

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  color: ${({ theme }) => theme.colors.black}B3;
  font-size: 14px;
  font-weight: bold;
  word-break: keep-all;
`

const StyledButton = styled(Button)`
  &&& {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    box-shadow: none !important;
  }
`

const ErrorContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  padding: 25px 60px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  margin: 0 auto;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      padding: 25px 20px 25px 50px;
      margin: auto;
    `)};
`

const BoldWrapper = styled.div`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: bold;
`

const CIconAlert = styled(IconAlert)`
  width: 20px;
  position: absolute;
  top: 1px;
  left: -25px;
`
