/* eslint-disable no-useless-escape */
const checkXSS = (value: string) => {
  const reg = /[\{\}\[\]\/?.,;:|\*~`!^\+<>@\#$%\\\=\"]/gi
  if (reg.test(value)) {
    return value.replace(reg, '')
  } else {
    return value
  }
}

export default checkXSS
