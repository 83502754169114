import styled, { useTheme } from 'styled-components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Tabs from '../../components/Tabs'
import Video from '../../components/Video'
import { iconContentPhoto } from '../../assets/icons'
import CropVideo from '../CropFile/CropVideo'
import useBreakpoint from '../../hooks/useBreakpoint'
import FileLoading from '../CropFile/FileLoading'
import { VideoData } from '../../utils/api/images'
import LoadingIndicator from '../../components/LoadingIndicator'
import MyWorker from 'worker-loader!./videoConversion.worker'

import {
  FileInput,
  Footer,
  PreviewPanel,
  PreviewTitle,
  Wrapper
} from './_stylesModal'
import { message } from 'antd'
import { files } from '../../utils/api'
import { convertURLtoFile, getDuration } from './constants'
import VideoList from './VideoList'

// TYPE //////////////////////////////////////////////////////////////

export type CropData = {
  x: number
  y: number
  width: number
  height: number
  startTime: number
}

type PhotoModalProps = ModalProps & {
  value?: string
  onComplete?(file: File | undefined, cropData: CropData): void
  isError?: boolean
  duration?: number
  minDuration?: number
  videoSize: VideoSizeType
}

interface VideoSizeType {
  width: number
  height: number
}

// TYPE FIN///////////////////////////////////////////////

const VideoModal = (props: PhotoModalProps) => {
  const {
    minDuration,
    onClose,
    visible,
    onComplete,
    duration = 30,
    isError = false,
    videoSize
  } = props
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)

  const [currentStep, setCurrentStep] = useState(0)

  const [fileLoading, setFileLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File>()

  const [selectedImageUrl, setSelectedImageUrl] = useState('')
  const [isConversion, setIsConversion] = useState(false)
  const [isPreviewError, setIsPreviewError] = useState(false)
  const [cropData, setCropData] = useState<CropData | null>(null)

  const { sizeName } = useBreakpoint()

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0)
        setSelectedImageUrl('')
        setSelectedFile(undefined)
        setCropData(null)
      }, 500)
    }
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [visible])

  const handleLoadedData = useCallback(() => {
    setFileLoading(false)
    setIsConversion(false)
  }, [])

  const handlePreviewError = useCallback(() => {
    setIsPreviewError(true)
    handleLoadedData()
  }, [handleLoadedData])

  const handleFileConversion = useCallback(
    async (result: { videoUrl: string } | null) => {
      if (!result) {
        handlePreviewError()
        return
      }
      const { videoUrl } = result
      setSelectedImageUrl(videoUrl)
      setSelectedFile(await convertURLtoFile({ url: videoUrl, noProxy: true }))
      handleLoadedData()
    },
    [handleLoadedData, handlePreviewError]
  )

  const handleInputFileChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    async (event) => {
      const file = event?.target?.files?.[0]
      if (!file || !minDuration) return
      setSelectedImageUrl('')

      const fileDuration = await getDuration(file).catch((error) =>
        console.log('getDuration error', error)
      )

      if (fileDuration && fileDuration < minDuration) {
        message.error(
          `해당 소스는 ${minDuration}초 이상의 동영상이어야 합니다.`
        )
        return
      }

      setFileLoading(true)
      setIsPreviewError(false)
      setSelectedFile(file)

      if (
        file.type === 'video/mp4' ||
        (file.type === 'video/quicktime' &&
          window.navigator.userAgent.indexOf('Mac OS') > -1)
      ) {
        // mp4 or ios + mov일 때 파일 변환 없이 바로 미리보기
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          setSelectedImageUrl(reader.result as string)
          setSelectedFile(file)
          handleLoadedData()
        }
      } else {
        setIsConversion(true)
        if (!window.Worker) {
          const result = await files().files.conversionFile({ file })
          handleFileConversion(result)
          return
        }
        const worker = new MyWorker()
        worker.postMessage({ file })
        worker.onmessage = async (e) => {
          const { result } = e.data
          handleFileConversion(result)
        }
        worker.onerror = () => {
          handlePreviewError()
          return
        }
      }
    },
    [minDuration, handlePreviewError, handleLoadedData, handleFileConversion]
  )

  //NOTE: PEXEL

  const onClickItem = useCallback(
    async (videoData: VideoData) => {
      setIsPreviewError(false)
      setFileLoading(videoData.video !== selectedImageUrl)
      setSelectedImageUrl(videoData.video)
      const file = await convertURLtoFile({
        url: videoData.video,
        noProxy: false
      })
      setSelectedFile(file)
      setFileLoading(false)
    },
    [selectedImageUrl]
  )

  const handleCropComplete = () => {
    if (!cropData || !selectedFile) return
    setCurrentStep(2)
    onComplete && onComplete(selectedFile, cropData)
  }

  useEffect(() => {
    handleCropComplete()
  }, [selectedFile, cropData])

  //NOTE: PEXEL FIN

  return (
    <Modal
      sizeName={sizeName}
      title='비디오 불러오기'
      visible={visible}
      closable={!(!window.Worker && isConversion)}
      backgroundColor={currentStep === 2 ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}
      onClose={() => {
        onClose && onClose()
      }}
    >
      <FileInput
        ref={inputRef}
        type='file'
        accept='video/*'
        data-testid='input-video'
        onChange={handleInputFileChange}
      />
      {currentStep === 0 && (
        <Wrapper>
          <PreviewPanel>
            <PreviewTitle>
              <Typography bold block center type='body2'>
                미리보기
              </Typography>
            </PreviewTitle>
            <ImageWrapper>
              {!selectedImageUrl && !fileLoading && (
                <EmptyPreview>
                  <img
                    src={iconContentPhoto}
                    alt='preview'
                    style={{ width: 56, marginBottom: 16 }}
                  />
                  <Typography
                    block
                    center
                    type='body2'
                    style={{ color: theme.colors.text.secondary }}
                  >
                    가지고 계신 영상 또는 무료 영상 중 <br />
                    마음에 드는 것을 선택해주세요.
                  </Typography>
                </EmptyPreview>
              )}
              {fileLoading && (
                <LoadingContainer>
                  <LoadingIndicator />
                  {isConversion && (
                    <div>
                      <div>파일을 인코딩하는 중입니다.</div>
                      <div style={{ marginTop: 4 }}>잠시만 기다려주세요.</div>
                    </div>
                  )}
                </LoadingContainer>
              )}
              {!fileLoading && selectedImageUrl && selectedFile && (
                <Video
                  videoClassName='selected-image'
                  src={selectedImageUrl}
                  isPreviewError={isPreviewError}
                  handleLoadedData={handleLoadedData}
                  handlePreviewError={handlePreviewError}
                  muted
                  autoPlay
                  playsInline
                />
              )}
            </ImageWrapper>
          </PreviewPanel>

          <Tabs
            tabs={[
              {
                title: '파일',
                onClick: () => {
                  inputRef.current?.click()
                }
              },
              {
                title: '무료 비디오',
                children: (
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <VideoList
                      minDuration={minDuration}
                      visible={visible}
                      fileLoading={fileLoading}
                      onClickItem={onClickItem}
                      selectedImageUrl={selectedImageUrl}
                    />
                  </div>
                )
              }
            ]}
            selectedTabIndex={1}
          />

          <Footer>
            <Button
              primary
              size='small'
              disabled={isPreviewError || fileLoading || !selectedImageUrl}
              onClick={async () => {
                setCurrentStep(1)
              }}
              loading={fileLoading}
              style={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: 84
              }}
            >
              불러오기
            </Button>
          </Footer>
        </Wrapper>
      )}
      {currentStep === 1 && (
        <CropVideo
          video={selectedImageUrl}
          videoSize={videoSize}
          duration={duration}
          onComplete={(data) => setCropData(data)}
        />
      )}
      {currentStep === 2 && (
        <FileLoading isError={isError} onClose={onClose ? onClose : () => {}} />
      )}
    </Modal>
  )
}

export default VideoModal

//STYLE //////////////////////////////////////////////////////////////////////

const ImageWrapper = styled.div`
  position: relative;
  padding: 0 2rem;
  height: 30vh;
  display: block;

  .selected-image {
    width: 100%;
    height: 30vh;
    object-fit: contain;
    object-position: center center;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${({ theme }) =>
    theme.breakpoints.small(`
      height: 100%;
  `)}
`

const EmptyPreview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
