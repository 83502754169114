import { Project } from '../types/project'
import { service } from '../utils/api'

export const parseProjectData = (data: Project) => {
  const newData = { ...data }
  const lastSceneType = newData.lastSceneType
  if (typeof lastSceneType === 'number') {
    const lastSceneIndex = newData?.design?.sources?.length - 1
    const lastSceneFromOriginal = newData?.design?.sources?.[lastSceneIndex]
    const lastSceneFromDesign =
      newData?.design?.lastsources?.[lastSceneType - 1]
    if (lastSceneFromOriginal) {
      if (lastSceneFromDesign) {
        lastSceneFromOriginal.sceneImage =
          newData?.design?.lastsources[lastSceneType - 1].sceneImage
      }
      let otIndex = 0
      let olIndex = 0
      lastSceneFromOriginal.source = lastSceneFromOriginal.source
        .map((item) => {
          if (item.sourceType === 'OT') {
            const lastSceneSource = lastSceneFromDesign?.source?.filter(
              ({ sourceType }) => {
                return sourceType === 'OT'
              }
            )[otIndex++]
            if (lastSceneSource) {
              return lastSceneSource
            }
          }

          if (item.sourceType === 'OL') {
            const lastSceneSource = lastSceneFromDesign?.source?.filter(
              ({ sourceType }) => {
                return sourceType === 'OL'
              }
            )[olIndex++]
            if (lastSceneSource) {
              return lastSceneSource
            }
          }

          return item
        })
        .map((item) => {
          const newItem = { ...item }
          if (lastSceneType === 3 || lastSceneType === 1) {
            if (newItem.sourceType === 'OT') {
              newItem.sourceType = 'T'
            }
          }

          if (lastSceneType === 3 || lastSceneType === 2) {
            if (newItem.sourceType === 'OL') {
              newItem.sourceType = 'I'
            }
          }
          return newItem
        })
      // .filter(({ sourceType }) =>
      //   ["I", "V", "T"].includes(`${sourceType}`.toUpperCase())
      // );
      newData.design.sources[lastSceneIndex] = lastSceneFromOriginal
    }
  }
  return newData
}

export const fetchProject = async (projectId: string) => {
  if (!projectId) return
  const res = await service().projects.findOne(projectId)
  return res && parseProjectData(res)
}

export const patchProject = async (project?: Project) => {
  if (project) {
    const res = await service().projects.patch(project._id, {
      userSource: project?.sources,
      bgmUrl: project?.bgmUrl,
      bgm: project?.bgm?._id,
      changedColor: project?.changedColor,
      lastSceneType: project?.lastSceneType,
      contactNumber: project?.contactNumber,
      title: project?.title || ''
    })
    if (!res && project?.title) {
      throw new Error('동일한 제목의 프로젝트가 존재합니다.')
    }
    return res
  }
  return
}
