import { createGlobalStyle, css } from 'styled-components'
import reset from 'styled-reset'

const globalStyles = createGlobalStyle`
  ${reset};
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  &&& {
    .ant-tooltip-arrow {
      display: none !important;
    }
    .ant-tooltip-content {
      width: fit-content;
      padding: 0 !important;
      border-radius: 10px !important;
      background-color: ${({ theme }) => theme.colors.text.secondary};
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
    }
    .ant-tooltip-inner {
      box-shadow: none !important;
      border-radius: 10px !important;
      background-color: inherit;
      white-space: nowrap;
      pointer-events: none;
      padding: 8px 10px !important;
    }

    // NOTE: antd 체크박스 색깔 세팅 
    .ant-checkbox-wrapper {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
    
    .ant-checkbox-inner {
      &:hover {
        border-color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors.primary} !important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      border-color: ${({ theme }) => theme.colors.primary} !important;
    }
  }    
 

  .vplateVideoEditorWrapper {
    ${({ theme }) =>
      theme.breakpoints.xxxLarge(css`
        font-size: 16px;
      `)}

    ${({ theme }) =>
      theme.breakpoints.small(css`
        font-size: 12px;
      `)}

    * {
      &, &::after, &::before {
        box-sizing: border-box;
      }
      outline-style: none;
      box-shadow: none;
    }
    svg {
      display: inline-block;
      vertical-align: top;
    }

    .modal-transition-enter {
      opacity: 0;
      transform: translate(-50%, -60%) scale(1.1);
    }
    .modal-transition-enter-active {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition: all 0.2s ease-in-out;
    }
    .modal-transition-exit {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    .modal-transition-exit-active {
      opacity: 0;
      transform: translate(-50%, -60%) scale(1.1);
      transition: all 0.2s ease-in-out;
    }

    @media only screen and (max-width: 1024px) {
      .modal-transition-enter {
        transform: translate(0%, 100%);
      }
      .modal-transition-enter-active {
        transform: translate(0%, 0%);
        transition: all 0.2s ease-in-out;
      }
      .modal-transition-exit {
        transform: translate(0%, 0%);
      }
      .modal-transition-exit-active {
        transform: translate(0%, 100%);
        transition: all 0.2s ease-in-out;
      }
    }

    .drawer-transition-top-enter {
      transform: translateY(-100%);
    }
    .drawer-transition-top-enter-active {
      transform: translateY(0%);
      transition: transform 0.3s ease-in-out;
    }
    .drawer-transition-top-exit {
      transform: translateY(0%);
    }
    .drawer-transition-top-exit-active {
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
    }
    
    .drawer-transition-right-enter {
      transform: translateX(100%);
    }
    .drawer-transition-right-enter-active {
      transform: translateX(0%);
      transition: transform 0.3s ease-in-out;
    }
    .drawer-transition-right-exit {
      transform: translateX(0%);
    }
    .drawer-transition-right-exit-active {
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
    }

    .drawer-transition-bottom-enter {
      transform: translateY(100%);
    }
    .drawer-transition-bottom-enter-active {
      transform: translateY(0%);
      transition: transform 0.3s ease-in-out;
      transition-delay: 0s;
    }
    .drawer-transition-bottom-exit {
      transform: translateY(0%);
    }
    .drawer-transition-bottom-exit-active {
      transform: translateY(100%);
      transition: transform 0.3s ease-in-out;
      transition-delay: 0s;
    }
    
    .drawer-transition-left-enter {
      transform: translateX(-100%);
    }
    .drawer-transition-left-enter-active {
      transform: translateX(0%);
      transition: transform 0.3s ease-in-out;
    }
    .drawer-transition-left-exit {
      transform: translateX(0%);
    }
    .drawer-transition-left-exit-active {
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }
    /* //NOTE: OVERRIDE react-color-palette css */
    .rcp-light {
      --rcp-background: #ffffff;
      --rcp-input-text: #111111;
      --rcp-input-border: rgba(0, 0, 0, 0.1);
      --rcp-input-label: #717171;
}

    .rcp-dark {
      --rcp-background: #181818;
      --rcp-input-text: #f3f3f3;
      --rcp-input-border: rgba(255, 255, 255, 0.1);
      --rcp-input-label: #999999;
    }

    .rcp {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: var(--rcp-background);
      border-radius: 10px;
    }

    .rcp-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;

      box-sizing: border-box;
      padding: 0;
      padding-top: 8px;
    }

    .rcp-saturation {
      position: relative;
      width: 100%;
      background-image: linear-gradient(transparent, black), linear-gradient(to right, white, transparent);
      -webkit-user-select: none;
      user-select: none;
      border-radius: 0;
    }

    .rcp-saturation-cursor {
      position: absolute;

      width: 8px;
      height: 8px;
      transform: translate(-50%, -50%);
      border: 2px solid #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      box-sizing: border-box;

    }

    .rcp-hue {
      position: relative;

      width: 100%;
      height: 12px;
      
      background-image: linear-gradient(
        to right,
        rgb(255, 0, 0),
        rgb(255, 255, 0),
        rgb(0, 255, 0),
        rgb(0, 255, 255),
        rgb(0, 0, 255),
        rgb(255, 0, 255),
        rgb(255, 0, 0)
      );
      border-radius: 0;

      user-select: none
    }

    .rcp-hue-cursor {
      position: absolute;

      width: 12px;
      height: 12px;
      transform: translateX(-50%);
      border: 2px solid #ffffff;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
      box-sizing: border-box;

    }

    .rcp-alpha {
      position: relative;

      width: 100%;
      height: 12px;

      border-radius: 10px;

      user-select: none;
    }

    .rcp-alpha-cursor {
      position: absolute;

      width: 20px;
      height: 20px;

      border: 2px solid #ffffff;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
      box-sizing: border-box;

      transform: translate(-10px, -4px);
    }

    .rcp-fields {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px;

      width: 100%;
    }

    .rcp-fields-element {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      width: 100%;
    }

    .hex-element {
      grid-row: 1;
    }

    .hex-element:nth-child(3n) {
      grid-column: 1 / -1;
    }

    .rcp-fields-element-input {
      width: 100%;
      
      font-size: 14px;
      font-weight: 600;

      color: var(--rcp-input-text);
      text-align: center;

      background: none;
      border: 2px solid;
      border-color: var(--rcp-input-border);
      border-radius: 5px;
      box-sizing: border-box;
      
      outline: none;

      padding: 10px;
    }

    .rcp-fields-element-label {
      display: none;
    }
  } 
`

export default globalStyles
