import React, { useRef } from 'react'
import styled from 'styled-components'
import { IconArrowHeadLeft, IconArrowHeadRight } from '../../assets/icons'
import { useProjectContext } from '../../hooks/useProject'
import SceneItem from './SceneItem'

type SceneListProps = {
  onSceneChange?(index: number): void
  selectedSceneIndex?: number
}

const SceneList = ({ onSceneChange, selectedSceneIndex }: SceneListProps) => {
  const { sceneList } = useProjectContext()
  const listWrapperRef = useRef<HTMLDivElement>(null)
  const handleListWheel: React.WheelEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.scrollLeft += e.deltaY * 1.5
  }
  const scrollList = (offset: number) => {
    listWrapperRef.current?.scrollTo({
      left: listWrapperRef.current.scrollLeft + offset,
      behavior: 'smooth'
    })
  }
  return (
    <Wrapper>
      <ArrowButton
        onClick={() =>
          scrollList(-(listWrapperRef.current?.offsetWidth || 0) / 2)
        }
      >
        <IconArrowHeadLeft width={20} />
      </ArrowButton>
      <ListWrapper ref={listWrapperRef} onWheel={handleListWheel}>
        <ListBody>
          {sceneList?.map(({ sceneImage, isCompleted }, index) => (
            <SceneItem
              index={index + 1}
              key={sceneImage}
              thumbnailSrc={sceneImage}
              onClick={() => onSceneChange && onSceneChange(index)}
              selected={selectedSceneIndex === index}
              completed={isCompleted}
            />
          ))}
        </ListBody>
      </ListWrapper>
      <ArrowButton
        onClick={() =>
          scrollList((listWrapperRef.current?.offsetWidth || 0) / 2)
        }
      >
        <IconArrowHeadRight width={20} />
      </ArrowButton>
    </Wrapper>
  )
}

export default SceneList

const Wrapper = styled.div`
  width: 100%;
  height: 72px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.text.primary};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`

const ArrowButton = styled.button`
  width: 32px;
  background: transparent;
  position: relative;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  polyline,
  path {
    fill: transparent;
    stroke: white;
  }
  .cls-2 {
    stroke: none;
  }
  .cls-1 {
    stroke: none;
  }
`

ArrowButton.defaultProps = { type: 'button' }

const ListWrapper = styled.div`
  height: 100%;
  display: block;
  flex: 1;
  overflow-x: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const ListBody = styled.div`
  min-width: 100%;
  width: auto;
  height: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
`
